import React from "react";
import { Container, Row } from "react-bootstrap";
import Cards from "./ContactComponents/Cards";
// import Form from './ContactComponents/Form/Form'
import ContactUs from "../../Assets/contact/Contact-01.png";

import EmailUs from "../../Assets/contact/EmailUs-01.png";
import Kolkata from "../../Assets/contact/kolkata-01.png";
import LetsTalk from "../../Assets/contact/LetsTalk-01.png";
import Mumbai from "../../Assets/contact/Mumbai-01.png";
import Gujarat from "../../Assets/contact/Gujarat.png";
import Delhi from "../../Assets/contact/Delhi.png";

import FormController from "../Home/HomeComponents/Form/FormController";
import { Helmet } from "react-helmet";
// import GoogleMapReact from "google-map-react";

// const Image = styled.div`
//   position: relative !important;
//   @media (max-width: 768px) {
//       padding: 125px 0 0;
//       background-size: contain !important;
//       background-image: url(${ContactUs2});
//   }
//   padding: 165px 0 125px;
//   background-image: url(${ContactUs});
//   background-size: cover !important;
//   overflow: hidden !important;
//   background-position: center !important;
//   background-repeat: no-repeat !important;
//   margin-bottom: 35px;
// `;

// const LocationPin = ({ text }) => (
//   <div className="pin">
//     <i class="fa fa-map-marker" aria-hidden="true"></i>
//     <p className="pin-text">{text}</p>
//   </div>
// );

export default class Contact extends React.Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    let structuredJSON = JSON.stringify({
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://www.ebullientech.io/",
      "contactPoint": [{
        "@type": "ContactPoint",
        "contactType": "business enquiries",
        "name": "Mithu Sarkar",
        "description": "Business Enquiries",
        "telephone": "+91-982013113",
        "email": "info@ebullientech.io",
        "hoursAvailable": "Mo-Fr 10:30-19:00"
      }]
    });

    return (
      <div style={{ backgroundColor: "rgb(243 243 243)" }}>
        <Helmet>
          <title>
            Get In Touch With Your Business Enquiries
          </title>
          <meta
            name="description"
            content=" Connect with us - Ebullientech Interactive"
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          <script data-react-helmet="true" className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>
        </Helmet>
        <img src={ContactUs} alt="Ebullientech Contact Us" title="Ebullientech Contact Us" style ={{width: '100%'}}/>

        <Container style ={{paddingTop:"40px"}}>
          <Row>
            <Cards
              image={Mumbai}
              imgTitle="Ebullientech Mumbai"
              title="Mumbai"
              delay=""
              width_i="100%"
              icon="fas fa-map-marker-alt"
              loc_title="Mumbai"
              content=""
            />
            <Cards
              delay=""
              image={Kolkata}
              imgTitle="Ebullientech Kolkata"
              title="Kolkata"
              width_i="100%"
              icon="fas fa-map-marker-alt"
              loc_title="Kolkata"
              content=""
            />
            <Cards
              image={Gujarat}
              imgTitle="Ebullientech Gujarat"
              title="Gujarat"
              delay=""
              width_i="100%"
              icon="fas fa-map-marker-alt"
              loc_title="Gujarat"
              content=""
            />
            <Cards
              image={Delhi}
              imgTitle="Ebullientech Delhi NRC"
              title="Delhi NRC"
              delay=""
              width_i="100%"
              icon="fas fa-map-marker-alt"
              loc_title="Delhi NRC"
              content=""
            />

            <Cards
              delay="200"
              image={LetsTalk}
              imgTitle="Ebullientech Let's Talk"
              title="Let's Talk"
              width_i="70%"
              icon="fas fa-comments"
              loc_title="Let's talk"
              tel2={+919820133258}
              telno2="+91-9820133258"
            />

            <Cards
              delay="300"
              image={EmailUs}
              imgTitle="Ebullientech Email Us"
              title="Email Us"
              width_i="70%"
              icon="fas fa-comments"
              loc_title="Email-us"
              mailto={"info@ebullientech.io"}
              mailContent="info[at]ebullientech[dot][io]"
            ></Cards>
          </Row>
        </Container>
        <div>
          {/* Form Component called from Home Content */}
          <FormController />
        </div>
        {/* <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key:'AIzaSyB7t-7jCSuwy0EJK9aStYvC7rLEjpCMBrY'}}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          > */}
        {/* <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            /> */}
        {/* </GoogleMapReact> */}
        {/* </div> */}

      </div>
    );
  }
}
