import styled from  'styled-components'
// import {device} from '../../DeviceBreakpoints'


export const CustomContainer = styled.div`
    font-family:'MuseoSans-300';
    padding: 30px 0px;
    background-color: #f7f7f7;
`

export const Section = styled.section`
  padding: 40px 0;
  overflow: hidden;
  background: #f7f7f7;
`;

export const BodyImg = styled.img`
    height:auto;
    width:50% ;
    display:block;
    margin-right: 20px;
    margin-bottom: 10px;
    float: left;
    @media (max-width: 768px) {
        width:100%
    }

`

export const Partner = styled.h4`
    color: #232323;
    font-family : 'MuseoSans-300', sans-serif;
    font-size: 21px;
    font-weight: 400;
    margin: 5px 0 20px 0;
    line-height: normal;

    ::after{
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        background: #3388C9;
        margin-top: 10px;
        -webkit-transition-duration: .4s;
        transition-duration: 0.4s;
    }
`

export const H4 = styled.h3`
    margin-bottom:15px;
    margin: 0 0 20px;
    padding: 0;
    letter-spacing: 0;
    font-family: 'MuseoSans-300';
    font-weight: 400;
    font-size: 21px;
    color: #232323;
    line-height: normal;


`

export const FoundersSection = styled.div`
    margin-bottom: 25px;
    text-align: center;
`

export const PartnerImg = styled.img`
    max-width: 100%;
    height: auto;
`

export const PartnerText = styled.div`
    padding: 25px;
`

export const FounderText = styled.div`
    padding: 25px 40px 25px 0;
    @media (max-width: 768px) {
        padding: 15px;
    }
`
//
// 25px 40px 25px 0
//
export const Ul = styled.ul`
    margin-bottom:0;
    list-style:none;
`

export const List =styled.li`
    text-align: center;
    margin-right: 4px;
    display: inline-block;
`

export const A =styled.a`
    /* background: #3388C9;
    border: 1px solid #3388C9;
    color: #fff; */
    border-radius: 35px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    display: inline-block;
    &:hover{
        background: white;
        color :#3388C9
    }


`
export const Title  = styled.span`
    color: #3388C9;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
`

export const Title2  = styled.span`    
    color: #3388C9;
    font-size: 10px;
    font-weight: 500;    
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 768px) {
        font-size: 12px;
        letter-spacing: 3px;
        padding-left: 15px;
    }
`
export const PartnerText2 = styled.div`
    padding: 5px;
    @media (max-width: 768px) {
        padding: 25px;
    }
`

export const Partner2 = styled.h4`
    
    color: #232323;
    font-family : 'MuseoSans-300', sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0 20px 0;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 21px;
        padding-left: 15px;
    }

    ::after{
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        background: #3388C9;
        margin-top: 10px;
        -webkit-transition-duration: .4s;
        transition-duration: 0.4s;
    }
`