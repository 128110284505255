import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import { device } from '../../../../DeviceBreakpoints';

export const SectionHeading = styled.div`
    margin-bottom: 50px;
    text-align: center;
    font-family:'MuseoSans-300';

`
export const P = styled.p`
    position: relative;
    font-family: 'MuseoSans-300';
    font-size: 14px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0 auto;
    word-spacing:1px;
    line-height:25px;

`
export const H3 = styled.h4`
    font-size:30px;
    margin: 10px 0px 10px 0px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
    font-family: 'MuseoSans-500', sans-serif;
    color: #232323;
    line-height: normal;

    /* margin-bottom: 0;
    font-weight: 400;
    position: relative;
    line-height: 140%;
    font-family:MuseoSans-500; */
`
export const Control = styled.div`
    background: linear-gradient(-45deg, #3388C9, #459de0);
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 28px;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition: all linear 400ms;
    transition: all linear 400ms;
    border: none;
    @media (min-width: 768px) {
    /* left : -4px; */
    }

`
export const ValidationText = styled.p`
    color:red;

`

export const Btn = styled.button`
    background: linear-gradient(-45deg, #3388C9, #459de0);
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border:none;
    outline:none;
    font-family:MuseoSans-500;

    &:before{
        background: #3388C9;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: inline-block;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 51%;
        -ms-transform-origin: 51%;
        transform-origin: 51%;
        -webkit-transition-property: -webkit-transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        border-radius: 22px;
        border: none;
    }
`

export const AlertMessage = styled.div`

   color: ${props =>{
       if(props.response_status === 0){
           return 'red'
       }
       else{
           return 'green'
       }
   }};
   font-family:OpenSans;


`

export const StyledCol = styled(Col)`


    @media ${device.mobileS} {
        left:-43px;
    }
    @media ${device.mobileM} {
        left:-30px;
    }
    @media ${device.mobileL} {
        left:-28px;
    }
    @media ${device.tablet}{
        left:0px

    }


`
