import React from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Cards from "./StyledProcessesandTools.js";
import { Helmet } from "react-helmet";
import Services from "../../Assets/tools/Processes_Tools.jpg";

import Card_1 from "../../Assets/tools/jira-software.png";
import Card_2 from "../../Assets/tools/bitbucket_logo.png";
import Card_3 from "../../Assets/tools/slack_logo.png";
import Card_4 from "../../Assets/tools/assettiger-logo.png";
import Card_5 from "../../Assets/tools/zoom-logo.png";
import Card_6 from "../../Assets/tools/adobe.png";
import Card_7 from "../../Assets/tools/google_workspace_logo_e.png";
import Card_8 from "../../Assets/tools/aws_logo.png";
import Card_9 from "../../Assets/tools/google-cloud-platform.png";
import Card_10 from "../../Assets/tools/office365-logo.png";
import Card_11 from "../../Assets/tools/omnigraffle-logo.png";
import Card_12 from "../../Assets/tools/apple-logo.png";
import Card_13 from "../../Assets/tools/sonarqube.svg";
import Card_14 from "../../Assets/tools/gitlab.svg";
import Card_15 from "../../Assets/tools/zap.jpeg";
import Card_16 from "../../Assets/tools/selenium.png";
import Card_17 from "../../Assets/tools/browserstack.png";
import Card_18 from "../../Assets/tools/redline13.png";
import Card_19 from "../../Assets/tools/razorpayx.svg";
import Card_20 from "../../Assets/tools/qb.png";
import Card_21 from "../../Assets/tools/Razorpay.svg";

// import VisibilitySensor from "react-visibility-sensor";

const DivSection = styled.div`
  margin-top: 5vh;
  @media (max-width: 768px) {
    margin-top: 0vh;
    margin-bottom: 7vh;
  }
`;

const Section = styled.section`
  padding: 40px 0;
  overflow: hidden;
  background: #f7f7f7;
`;

export default class ProcessesAndTools extends React.Component {
  render() {
    let structuredJSON = JSON.stringify({
      "@context": "http://schema.org/",
      "@type": "ToolsandProcessPage",
      "name": "Tools and Process",
      "description":"Keeping in mind our customer's business goals, we constantly upgrade ourselves with the latest trends and technologies.",
      "url": "https://www.ebullientech.io/processes-and-tools"
    });
    return (
      <div>
        <Helmet>
          <title>
            Ebullientech - Our Processes, Tools & Technologies
          </title>
          <meta
            name="description"
            content="Keeping in mind our customer’s business goals, we constantly upgrade ourselves with the latest trends and technologies."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>}
        </Helmet>

        <img src={Services} alt="Ebullientech Processes and Tools" title="Ebullientech Processes and Tools" style ={{width: '100%'}}/>

        <Section>
          <Container>
          <Row style={{ marginBottom: "20px" }}>
          <DivSection className="col-md-5">
            <h5 style={{ fontFamily:'MuseoSans-300'}}>TEAM. CUSTOMER. COMMUNITY</h5>
            <h2 style={{ marginTop:'2vh',marginBottom:'2vh', fontFamily:'MuseoSans-500'}}>We Work With the&nbsp;<br/>Best Processes and Tools</h2>
            <p  style={{ fontFamily:'MuseoSans-300'}}>We specialise in problem solving. Owning to the many years of experience in software development and product engineering, we have had the opportunity to learn different software tools and methods. In our flight from puzzle to solution, we have chosen to partner with first-string processes and tools, keeping in mind our customers' business goals. It is the efficiency of the world-class tools combined with our team's talent and experience that forms the backbone of our <a href="/our-services">service offerings.</a></p>
          </DivSection>
          <div className="col-md-7">
            <Row style={{ marginBottom: "40px" }}>

              <Cards
                delay=""
                image={Card_12}
                imgWidth="50%"
                imgTitle="Ebullientech - Apple"
                title="Local Infrastructure - Apple"
              />

              <Cards
                delay=""
                image={Card_8}
                imgWidth="50%"
                icon="fas fa-mobile"
                imgTitle="Ebullientech - Amazon Web Services"
                title="Public Cloud - Amazon Web Services"
              />

              <Cards
                delay="300"
                image={Card_9}
                imgWidth="40%"
                icon="fas fa-bullseye"
                title="Location Intelligence, AI, ML - Google Cloud Platform"
                imgTitle="Ebullientech - Google Cloud Platform"
              />

            </Row>

            <Row style={{ marginBottom: "40px" }}>

              <Cards
                delay=""
                image={Card_13}
                imgWidth="80%"
                imgTitle="Ebullientech - SonarQube"
                title="Code Quality and Code Security - SonarQube"
              />

              <Cards
                delay=""
                image={Card_14}
                imgWidth="80%"
                imgTitle="Ebullientech - Gitlab"
                title="Static Application Security Testing (SAST) - Gitlab"
              />

              <Cards
                delay="300"
                image={Card_15}
                imgWidth="40%"
                title="Web App Scanner - ZAP"
                imgTitle="Ebullientech - ZAP"
              />

            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Cards
                delay="300"
                image={Card_6}
                imgWidth="40%"
                title="Design & UX - Adobe"
                imgTitle="Ebullientech - Adobe"
              />

              <Cards
                delay="400"
                image={Card_11}
                imgWidth="40%"
                icon="fas fa-mobile"
                imgTitle="Ebullientech - Omnigraffle"
                title="Visual Communication - Omnigraffle"
              />

              <Cards
                delay="400"
                image={Card_10}
                imgWidth="50%"
                title="Productivity & Documentation - Microsoft Office 365"
                imgTitle="Ebullientech - Microsoft Office 365"
              />
            </Row>

            <Row style={{ marginBottom: "20px" }}>

              <Cards
                delay="200"
                image={Card_1}
                imgWidth="40%"
                title="Project Management - Atlassian Jira"
                imgTitle="Ebullientech - Atlassian Jira"
              />

              <Cards
                delay="200"
                image={Card_2}
                imgWidth="40%"
                imgTitle="Ebullientech - Atlassian Bitbucket"
                title="Source Code Repository - Atlassian Bitbucket"
              />

              <Cards
                delay="200"
                image={Card_3}
                imgWidth="40%"
                imgTitle="Ebullientech - Slack"
                title="Team Communication - Slack"
              />

            </Row>

            <Row style={{ marginBottom: "20px" }}>

              <Cards
                delay="300"
                image={Card_16}
                imgWidth="40%"
                title="Automation Testing - Selenium"
                imgTitle="Ebullientech - Selenium"
              />

              <Cards
                delay="400"
                image={Card_17}
                imgWidth="50%"
                imgTitle="Ebullientech - BrowserStack"
                title="App & Browser Testing - BrowserStack"
              />

              <Cards
                delay="400"
                image={Card_18}
                imgWidth="40%"
                imgTitle="Ebullientech - RedLine13"
                title="Cloud Load Testing - RedLine13"
              />

            </Row>

            <Row style={{ marginBottom: "20px" }}>

              <Cards
                delay=""
                image={Card_5}
                imgWidth="40%"
                imgTitle="Ebullientech - Zoom"
                title="Enterprise Video Communication - Zoom"
              />

              <Cards
                delay="400"
                image={Card_20}
                imgWidth="30%"
                imgTitle="Ebullientech - QuickBooks"
                title="Accounting - QuickBooks"
              />

              <Cards
                delay="400"
                image={Card_19}
                imgWidth="70%"
                imgTitle="Ebullientech - RazorPayX"
                title="Neo-banking - RazorPayX"
              />

            </Row>

            <Row style={{ marginBottom: "20px" }}>

              <Cards
                delay="300"
                image={Card_7}
                imgWidth="60%"
                title="Enterprise Email & Collaboration - Google Workspace"
                imgTitle="Ebullientech - Google Workspace"
              />

              <Cards
                delay="300"
                image={Card_21}
                imgWidth="60%"
                title="HR & Payroll - RazorPay"
                imgTitle="Ebullientech - RazorPay"
              />

              <Cards
                delay="400"
                image={Card_4}
                imgWidth="70%"
                imgTitle="Ebullientech - AssetTiger"
                title="Asset Management - AssetTiger"
              />

            </Row>

            </div>
            </Row>
          </Container>
        </Section>
      </div>
    );
  }
}
