import React, { Component } from "react";
import { Container as Contain, Col, Row } from "react-bootstrap";
import NextLevel from "../../../../Assets/Home/NextLevel.png";
import styled from "styled-components";
import AccordianCard from "./AccordianCard";

var linkd = "our-services"

const Img = styled.img`
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
`;
const H4 = styled.h4`
  font-size: 30px;
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  font-weight: 400;
  font-family: "MuseoSans-500", sans-serif;
  color: #232323;
  line-height: normal;
`;

class Accordian extends Component {
  state = {
    Card1: false,
    Card2: false,
    Card3: false,
    Card4: false,
    Card5: false,
    Card6: false,
    Card7: false,
    Card8: false,
    Card9: false,
    Card10: false,
    Card11: false,
    Card12: false
  };
  render() {
    return (
      <section className="bg-light-gray" style={{ padding: "70px 0" }}>
        <Contain>
          <Row>
            <Col lg={5}>
              <Img src={NextLevel} alt="Image" />
            </Col>
            <Col lg={7}>
              <div style={{ paddingLeft: "30px" }}>
                <H4>It’s all about taking it to the next level.</H4>
                <AccordianCard
                  onClick={() => {
                    this.setState({
                      Card1: !this.state.Card1,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    });
                  }}
                  title="Mobile Applications"
                  state={this.state.Card1}
                >
                  We've got all that it takes to develop the apps that will
                  outrun all others in this glut of lacklustre mobile apps<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card2: !this.state.Card2,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card1: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="Product Engineering"
                  state={this.state.Card2}
                >
                  We have architected and built software applications for the
                  most complex business models. We are capable of working with
                  the latest open source technologies like Python, PHP, and
                  NodeJs<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card3: !this.state.Card3,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card1: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="Video OTT Streaming Technology (VoD and Live Streaming)"
                  state={this.state.Card3}
                >
                  We develop end-to-end video solutions for OTTs enabling user
                  experience across multiple screens. We have the capacity for
                  live streaming to video on demand which helps you to deliver
                  your content in a jiffy<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card4: !this.state.Card4,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card1: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="Cloud Computing and Consulting"
                  state={this.state.Card4}
                >
                  We help clients embrace new-age cloud technology to achieve
                  their business objectives<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card5: !this.state.Card5,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card1: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="eCommerce & CMS Driven Responsive Websites"
                  state={this.state.Card5}
                >
                  Development and integrating ecommerce portals to showcase and
                  sell your products directly online inclusive of payment
                  gateways and more<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card6: !this.state.Card6,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card1: false,
                      Card7: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="Search Engine Optimisation"
                  state={this.state.Card6}
                >
                  We help you grow your business in lightning speed with robust
                  SEO, SEM, social media, lead generation, and media buy<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card7: !this.state.Card7,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card1: false,
                      Card8: false,
                      Card9: false,
                    })
                  }
                  title="Social Media Marketing"
                  state={this.state.Card7}
                >
                  Establish and strengthen your brand awareness, engagement &
                  traffic with our social media marketing services<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card8: !this.state.Card8,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card1: false,
                      Card9: false,
                    })
                  }
                  title="Media Buying & PPC"
                  state={this.state.Card8}
                >
                  We manage all forms of end-to-end media planning and buying.
                  With years of experience, we empower you to achieve all your
                  objectives<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card9: !this.state.Card9,
                      Card12: false,
                      Card11: false,
                      Card10: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card1: false,
                    })
                  }
                  title="Business Coaching & Start-up Mentoring"
                  state={this.state.Card9}
                >
                  We help you strategise your business's growth and solve the
                  puzzles that are creating obstacles in your path<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card10: !this.state.Card10,
                      Card12: false,
                      Card11: false,
                      Card9: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card1: false,
                    })
                  }
                  title="Data & Business Analytics"
                  state={this.state.Card10}
                >
                  Mathematics is the foundation of algorithms and programming,
                  and statistics is the primary building block of<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card11: !this.state.Card11,
                      Card12: false,
                      Card10: false,
                      Card9: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card1: false,
                    })
                  }
                  title="Design & UX"
                  state={this.state.Card11}
                >
                  A great product idea needs a robust technology backbone,
                  and at the same time, it has to be visually appealing<a href={linkd}>... Know More</a>
                </AccordianCard>
                <AccordianCard
                  onClick={() =>
                    this.setState({
                      Card12: !this.state.Card12,
                      Card11: false,
                      Card10: false,
                      Card9: false,
                      Card2: false,
                      Card3: false,
                      Card4: false,
                      Card5: false,
                      Card6: false,
                      Card7: false,
                      Card8: false,
                      Card1: false,
                    })
                  }
                  title="Legal Process Outsourcing"
                  state={this.state.Card12}
                >
                  In the post pandemic era, availing legal aids has become
                  highly challenging. While it is almost impossible to<a href={linkd}>... Know More</a>
                </AccordianCard>
              </div>
            </Col>
          </Row>
        </Contain>
      </section>
    );
  }
}

export default Accordian;
