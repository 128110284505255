import React from 'react'
import { Container as Contain, Row, Col } from 'react-bootstrap'
import { Spring, } from 'react-spring/renderprops'
import BannerImage from '../../../../Assets/Home/HomeBanner.png'
import BannerFoot from '../../../../Assets/Home/HomeBannerFoot.png'
import {Text,P,BannerF,Button} from './BannerStyled'

var linkdOS = "our-services"
var linkdPT = "processes-and-tools"



const HeadBanner = () => {
    return (
        <div style={{ backgroundColor: "#55a3de", }}>
            <Contain >
                <Row >
                    <Col lg={6} style={{ paddingTop: "15%" ,paddingBottom:"5px"}} >
                        <Spring

                            from={{
                                opacity: 0,
                                marginTop: 200

                            }}
                            to={{
                                opacity: 1,
                                marginTop:0

                            }}

                            config={{duration:2000}}
                            >

                            {props => (
                                <div style={props}>
                                    <Text>We help you create <br/>game-changing digital products</Text>

                                </div>
                            )}
                        </Spring>
                        <Spring
                            config={{delay:1000 ,duration:1500}}
                            from={{
                                opacity: 0,

                            }}
                            to={{

                                opacity: 1,

                            }}>
                            {props => (
                                <div style={props}>
                                    <P>
                                        We bring the advantages of digital transformation to your enterprise. Our <a href={linkdOS} style={{color:"black"}}>services</a> and solutions empower our customers to take their business to the next level, help them accelerate growth, create new opportunities. Our architectural designs help our consumers optimise their cost more efficiently. We support ourselves with best in class, secure and industry compliant <a href={linkdPT} style={{color:"black"}}>processes and tools</a>. This year, we have onboarded our legal team, and now we are ready to help our clients solve their legal matters along with core technology problems.
                                     </P>
                                     <Button
                                       href={linkdOS}
                                       style={{ fontFamily: "MuseoSans-500" }}
                                     >
                                       Learn more
                                     </Button>
                                </div>
                            )}
                        </Spring>

                    </Col>
                    <Col lg={6}>
                        <BannerF >
                            <a href={linkdOS}><img  src = {BannerImage} className="img-fluid float-right width-100" title="Click here to know about Ebullientech services" alt="Click here to know about Ebullientech services" style={{ paddingTop: "5em" }} /></a>
                        </BannerF>
                    </Col>
                </Row>
            </Contain>
            <div >
                <img src={BannerFoot} className="img-fluid width-100" alt="" />
            </div>
        </div>
    );
}

export default HeadBanner;
