import React, { Component } from 'react'
import axios from 'axios'
import Form from './Form'


class FormController extends Component {
    state = {
        name: "",
        email: "",
        phone: "",
        Message: "",
        emailValidation: "",
        nameValidation: "",
        phoneValidation: "",
        messageValidation: "",
        disabled: false,
        response: "",
        responseStatus: "",
        AlertText: "",
        isVerified:"",
        verifiedValidation:""
    }

    validate = () => {

        let emailValidation = ""
        let nameValidation = ""
        let messageValidation = ""
        let phoneValidation = ""
        let verifiedValidation = ""


        // Validation if name is empty
        if (this.state.name === "") {
            nameValidation = "*This field is required"
            this.setState({
                nameValidation: nameValidation
            })
            return false;
        }
        else {
            this.setState({
                nameValidation: "",
            })
        }
        // Validation if the phone number is empty or invalid
        console.log(/^\d{10}$/.test(this.state.phone) + "validations")

        if (this.state.phone === "" || /^\d{10}$/.test(this.state.phone) === false) {
            phoneValidation = "Please Enter a valid Phone number"
            this.setState({
                phoneValidation: phoneValidation
            })
            return false;
        }
        else {
            this.setState({
                phoneValidation: "",
            })

        }

        // Validation if email is empty
        if (this.state.email === "") {
            emailValidation = "*This field is required"
            this.setState({
                emailValidation: emailValidation
            })
            return false;
        }
        else {
            this.setState({
                emailValidation: "",
            })
        }

        // Validation if email is valid
        if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
            emailValidation = "*Please add a Valid Email"
            this.setState({
                emailValidation: emailValidation
            })
            return false;
        }
        else {
            this.setState({
                emailValidation: "",
            })
        }

        // Validation if Message is empty
        if (this.state.Message === "") {
            messageValidation = "*This field is required"
            this.setState({
                messageValidation: messageValidation
            })
            return false;
        }

        else {
            this.setState({
                messageValidation: "",
            })
        }

        if (this.state.isVerified === ""){
            verifiedValidation = "*Please Verify Captcha"
            this.setState({
                verifiedValidation: verifiedValidation
            })
            return false;

        }else{
            this.setState({
                verifiedValidation: ""
            })
        }

        return true;
    }



    handleName = (name) => {
        const nameValue = name.target.value
        this.setState({
            name: nameValue
        })
    }

    handleEmail = (email) => {
        const emailValue = email.target.value
        this.setState({
            email: emailValue
        })


    }
    handlePhone = (Number) => {
        const numberValue = Number.target.value
        this.setState({
            phone: numberValue
        })


    }
    handleMessage = (Message) => {
        const messageValue = Message.target.value
        this.setState({
            Message: messageValue
        })

    }

    handleResponseStatus = () => {
        const responseStatus = this.state.responseStatus
        if (responseStatus === 0) {
            console.log("Not Sent")
            this.setState({
                AlertText: "*Something went wrong, please try again after sometime."
            })

        }
        else {
            this.setState({
                AlertText: "*Your query has been submitted, our support team will reach you soon."
            })
            console.log("Sent")

        }
    }
    captchaOnChange =(recaptchaToken)=>{
        this.setState({
            isVerified : recaptchaToken
        })
       
    }


    onSubmit =  () => {
        const isValid = this.validate()
        const isVerified = this.state.isVerified
        const nameString = this.state.name.toString()
        const emailString = this.state.email.toString().toLowerCase()
        const phoneString = this.state.phone.toString()
        const messageString = this.state.Message.toString()

        const data = {
            person_name: nameString,
            person_email: emailString,
            person_mobile: phoneString,
            person_massage: messageString,
        };


        console.log(data);
        if(isVerified !== null) {
            if (isValid ) {
                axios.post("https://crsd35zhm3.execute-api.us-east-1.amazonaws.com/v1/sentemail", data)
                   .then((res) => {
                       //Setting State According to the response
                       this.setState({ response: res, disabled: !this.state.disabled, responseStatus: res.data.body.status })
   
                       // Handles Alert Message According to response status
                       this.handleResponseStatus();
   
                       //Setting time out from Alert Text
                       setTimeout(() => {
                           this.setState({ AlertText: "" })
                       }, 5000);
   
   
                   })
   
                   .catch((err) => { console.log("Unable to send" + err) })
               this.setState({ disabled: !this.state.disabled ,email:"",phone:"",Message:"",name:""})
           }

        }
        else{
            this.setState({
                disabled:true
            })
        }

    }

    


    render() {
        return (
            <div>
                <Form
                    handleName={this.handleName}
                    nameValidation={this.state.nameValidation}
                    handlePhone={this.handlePhone}
                    handleEmail={this.handleEmail}
                    emailValidation={this.state.emailValidation}
                    handleMessage={this.handleMessage}
                    messageValidation={this.state.messageValidation}
                    phoneValidation={this.state.phoneValidation}
                    onSubmit={this.onSubmit}
                    disabled={this.state.disabled}
                    response={this.state.response}
                    responseStatus={this.state.responseStatus}
                    alertText={this.state.AlertText}
                    email= {this.state.email}
                    name= {this.state.name}
                    message= {this.state.Message}
                    phone= {this.state.phone}
                    captchaOnChange = {this.captchaOnChange}
                    isVerified = {this.state.isVerified}
                    verifiedValidation = {this.state.verifiedValidation}
                />
            </div>
        )
    }


}

export default FormController;