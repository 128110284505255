import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row } from "react-bootstrap";
import styled from 'styled-components'


export const Textone = styled.h1`
    color: white;
    text-shadow: 5px 4px 11px rgb(51 135 200), 0 2px 5px rgb(51 135 200);
    font-size:700%;
    /* font-weight:700; */
    margin-bottom:25px;
    text-align: center;
    font-family: 'MuseoSans-500';
`

export const Text = styled.h2`
    font-size:150%;
    color: gray;
    /* font-weight:700; */
    margin-bottom:50px;
    text-align: center;
    font-family: 'MuseoSans-500';
`

export const Text2 = styled.h4`
    /* font-weight:700; */
    margin-bottom:50px;
    text-align: center;
    font-family: 'MuseoSans-500';
`
const Section = styled.section`
  padding: 40px 0;
  overflow: hidden;
  background: #fff;
`;

const Accessmain = styled.div`
  @media (max-width: 768px) {
      padding-top: 25px;
  }
`;

const Atag = styled.a`
color:rgb(51 135 200);
&:hover{
  cursor:pointer;
  color: #fff;
}
`;


const Access = styled.div`
font-family: 'MuseoSans-500';
padding-top:10px;
color:rgb(51 135 200);
border-radius: 1em;
box-shadow:
  0 0.125em 0.3125em rgba(0, 0, 0, 0.25),
  0 0.02125em 0.06125em rgba(0, 0, 0, 0.25);
  &:hover{
    transition-duration: 0.7s;
      color: #fff;
      text-decoration:none;
      background: linear-gradient(-45deg, #3388C9, #459de0);
  }
  @media (max-width: 768px) {
  }
`;
var linkdCS = "our-services"
var linkdKP = "key-people"
var linkdPT = "processes-and-tools"


export default class Forofor extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
        <Helmet>
          <title>
            Ebullientech - 404
          </title>
          <meta
            name="description"
            content="We are a start-up India recognised, Indian Chamber of Commerce certified software and mobile app company. At Ebullientech, we leave no stone unturned to provide you with highest quality of services."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {/*<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>*/}
        </Helmet>

        <Section style={{ backgroundColor: "#f7f7f7" }}>
          <Container style={{ paddingTop: "40px",paddingBottom: "40px", backgroundColor: "#f7f7f7" }}>
            <Textone>404</Textone>
            <Text>We can't find the page you're looking for.</Text>
            <Text2>~ Visit something new today ~</Text2>
            <div className="row">
              <Accessmain className="col-md-3 col-6 text-center">
                <Atag href={linkdKP}><Access>
                  <label className="text-center">
                    <i className="fa fa-bullseye fa-2x"></i><br/>Key People
                  </label>
                </Access></Atag>
              </Accessmain>
              <Accessmain className="col-md-3 col-6 text-center">
                <Atag href={'/'}><Access>
                  <label className="text-center">
                    <i className="fa fa-coffee fa-2x"></i><br/>Home
                  </label>
                </Access></Atag>
              </Accessmain>
              <Accessmain className="col-md-3 col-6 text-center">
                <Atag href={linkdPT}><Access>
                  <label className="text-center">
                    <i className="fa fa-paint-brush fa-2x"></i><br/>Processes & Tools
                  </label>
                </Access></Atag>
              </Accessmain>
              <Accessmain className="col-md-3 col-6 text-center">
                <Atag href={linkdCS}><Access>
                  <label className="text-center">
                    <i className="fa fa-briefcase fa-2x"></i><br/>Our Services
                  </label>
                </Access></Atag>
              </Accessmain>
            </div>
          </Container>
        </Section>

      </div>
    );
  }
}
