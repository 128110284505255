import styled from "styled-components";
import { Card } from "react-bootstrap";

export const Title = styled.h5`
  margin-bottom: 15px;
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  /* font-weight: 600; */
  font-family: "MuseoSans-500";
  font-weight: 400;
  color: #232323;
  line-height: normal;
`;

export const Icon = styled.div`
  color: #3388c9;
  position: relative;
  padding: 20px 0;
  font-size: 60px;
  line-height: 1;
  width: 2em;
  margin-bottom: 25px;
  bottom: ${(props) => (props.flag ? "-20px" : 0)};
  margin-left: auto;
  margin-right: auto;
`;
export const CircleLarge = styled.div`
  left: 0;
  bottom: 0;
  width: 1.37em;
  height: 1.37em;
  background: rgba(78, 74, 200, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;

export const CircleSmall = styled.div`
  top: 0;
  right: 0;
  width: 0.59em;
  height: 0.59em;
  background: rgba(128, 137, 255, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    width: 2em;
    height: 2em;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 0;
  line-height: 25px;
  font-family: "MuseoSans-500";
  font-size: 14px !important;
  @media (max-width: 576px) {
    font-size: 12px !important;
  }
`;
export const SCard = styled(Card)`
  align-items: center;
  text-align: center;
  border: none;
  background: #f7f7f7;
  padding: 5px 25px 45px 25px;
  margin-bottom: 20px;
`;
