import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Founder1 from "../../Assets/keyPeople/Kamalika-Guha-Roy.png";
import Founder2 from "../../Assets/keyPeople/Mithu-Sarkar.png";
import AboutImg from '../../Assets/keyPeople/Key_People.jpg'

import mentor from "../../Assets/keyPeople/Bhaskar-Sen.png";
import chetan from "../../Assets/keyPeople/Chetan-Dsouza.png";
import sakuntala from "../../Assets/keyPeople/Shakuntala-Sen.png";
import sanjay from "../../Assets/keyPeople/Sanjay-Mehta.png";

import dmale from "../../Assets/dummy-icon-male.jpeg";
import dfemale from "../../Assets/Dummy-Icon-Female.jpeg";

import "./AboutUs.css";
import {
  Partner,
  Partner2,
  H4,
  FoundersSection,
  PartnerImg,
  PartnerText,
  PartnerText2,
  Ul,
  List,
  A,
  Title,
  Title2,
  FounderText,
  Section
} from "./StyledKeyPeople.js";

export default class KeyPeople extends React.Component {
  render() {
    let structuredJSON = JSON.stringify({
      "@context": "http://schema.org/",
      "@type": "KeyPeoplePage",
      "name": "Key People",
      "description":"Started in 2018 with a core team of 5 skilled and experienced professionals. Since then we have assembled an experienced in -house team of 12+ professionals.",
      "url": "https://www.ebullientech.io/key-people"
    });
    return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
        <Helmet>
          <title>
            Ebullientech - Our Team, Key People & Founders
          </title>
          <meta
            name="description"
            content="Started in 2018 with a core team of 5 skilled and experienced professionals. Since then we have assembled an experienced in-house team of 12+ professionals."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>}
        </Helmet>

        <img src={AboutImg} alt="Ebullientech Key People" title="Ebullientech Key People" style ={{width: '100%'}}/>

        <Section>
          <div className="container">

            {/*<H4>Our Toolbox</H4>
                    <p><strong>Core Technology, Frameworks, and UI Development – </strong>Python, Node.js, Java, PHP, Ruby, MySQL, PostgreSQL, MongoDB, Elasticsearch, Redis, Aerospike, AngularJS, Angular, ReactJS, Android, iOS, Django, Flask, Express.js, Nest.js, Spring Boot, Codeigniter, Laravel, Zend, Lumen, Phalcon<br />
                        <strong>Cloud Computing – </strong>Amazon Web Services, Google Cloud Platform<br />
                        <strong>Artificial Intelligence – </strong>Amazon Rekognition, Google Vision API, Machine Learning<br />
                        <strong>Digital Marketing and Analytics – </strong>Google AdWords, Google Keyword Planner, Google Analytics, MailChimp, Sendy Adobe Marketing Cloud, CleverTap, Localytics, Firebase Google Analytics, Flurry, Facebook Ads, LinkedIn Ads, Prediction.io<br />
                        <strong>Video Tech Solutions, Serverless, &amp; CDN – </strong>AWS Elemental Media Converter &amp; MediaTailor, BrightCove, BitMovin, Beamr, MUX, VideoJS, YOUBORA, Kaltura, Widevine, FairPlay, Lambda, CloudFront, Akamai<br />
                        <strong>Content Management Systems – </strong>WordPress, Shopify, Magento, Drupal, Wix, Squarespace, Typo3
                    </p> */}
            <section
              style={{
                padding: "15px 0",
                overflow: "hidden",
                background: "#fff",
              }}
            >
              <Container>
                <FoundersSection>
                  <H4> Chief Mentor </H4>
                </FoundersSection>
                <Row>
                  <Col lg={12} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ffffff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        <Col md={6} sm={12}>
                        <div className="text-center"><PartnerImg alt="Bhaskar-Sen" src={mentor} style = {{maxHeight:'35em'}} /></div>
                        </Col>
                        <Col md={6} sm={12}>
                          <PartnerText>
                            <Title style={{ paddingLeft: "15px" }}>
                              Chief Mentor
                            </Title>
                            <Partner style={{ paddingLeft: "15px" }}>
                              <a href="/people-p" style={{color:"black"}}>Bhaskar Sen</a>
                            </Partner>
                            <p
                              style={{
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                              Educated and trained as an engineer, he started
                              his career in 1965 with Greaves Cotton & Company
                              Limited, Foseco Division, Kolkata. During 70’s he
                              was engaged as Marketing Manager, Latin America,
                              Foseco Do Br­­asil Ltda, Sao Paolo and Foseco
                              Venezuela as Chief Executive. In mid 80’s he
                              joined Late Dr Sadhan. C. Dutt of DCPL to start up
                              Sika Qualcrete Pvt. Ltd. (presently known as Sika
                              India Pvt. Ltd.) a construction chemical company
                              in Kolkata. Thereafter Sika had branches all over
                              India with manufacturing facilities in Kolkata,
                              Goa, and Jaipur. Mr Sen remained as the Managing
                              Director from its inception till 2002. He is an
                              ex-president of BNCCI, member executive committee,
                              FICCI, New Delhi. Mr Sen was also, among other
                              universities, in the advisory boards of WBUT
                              (presently known as MAKAUT), Netaji Subhash Open
                              University, and in the governing body of BE
                              College, Shibpur, then a deemed University, for
                              eight years. Mr Sen had the privilege of
                              representing Govt. of West Bengal in the trustee
                              board of Indian Museum. He has been a member of
                              numbers of economic committees and bodies set up
                              by the state government. Mr Sen was an active
                              Rotarian and acted as honorary treasurer of
                              Calcutta Rotary Club, Rotary Sadan.
                            </p>
                          </PartnerText>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <br/>
            {/* <section
              style={{
                padding: "30px 0",
                overflow: "hidden",
                background: "#fff",
              }}
            >
              <Container>
                <FoundersSection>
                  <H4> Our Mentors & Advisors </H4>
                </FoundersSection>
                <Row>
                  <Col lg={4} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ffffff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        
                          <Col md={6} sm={12}>
                            <PartnerImg alt="partner" src={Founder1} />
                          </Col>
                       
                        <Col md={12} sm={12}>
                          <PartnerText>
                            <Title style={{ paddingLeft: "15px" }}>
                              Mentor
                            </Title>
                            <Partner style={{ paddingLeft: "15px" }}>
                              Shakuntala Sen
                            </Partner>
                            <div className="text-center"><PartnerImg alt="Shakuntala-Sen" src={sakuntala} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                              Shakuntala Sen, an MBA from Liverpool Business
                              School, United Kingdom is currently a senior
                              faculty member at Calcutta Institute of
                              Engineering and Management (CIEM), specialising in
                              Human Resource Management, Organisational
                              Behaviour, Entrepreneurship, and Business Ethics,
                              She is also the faculty leader of NEN (National
                              Entrepreneurship Network) and head of the
                              Entrepreneurship committee at CIEM. She is also an
                              Invitee member of the Governing Body of the
                              Enterprise Development Institute (EDI, Bengal
                              National Chamber of Commerce & Industry).
                            </p>
                          </PartnerText>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ffffff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        <Col md={6} sm={12}>
                                                    <PartnerImg alt="partner" src={Founder1} />
                                                </Col>
                        <Col md={12} sm={12}>
                          <PartnerText>
                            <Title style={{ paddingLeft: "15px" }}>
                              Mentor
                            </Title>
                            <Partner style={{ paddingLeft: "15px" }}>
                              Sanjay Mehta
                            </Partner>
                            <div className="text-center"><PartnerImg alt="Sanjay-Mehta" src={sanjay} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                              Sanjay Mehta, is an MBA and Green Belt Lean Six
                              Sigma certificate holder, currently working with
                              Morgan Stanley where he manages IT and Telecom
                              procurement for EMEA region.
                            </p>
                          </PartnerText>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ffffff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        <Col md={6} sm={12}>
                                                    <PartnerImg alt="partner" src={Founder1} />
                                                </Col>
                        <Col md={12} sm={12}>
                          <PartnerText>
                            <Title style={{ paddingLeft: "15px" }}>
                              Advisor
                            </Title>
                            <Partner style={{ paddingLeft: "15px" }}>
                              Chetan DSouza
                            </Partner>
                            <div className="text-center"><PartnerImg alt="Chetan-Dsouza" src={chetan} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                              Chetan DSouza, founder and director of KidzByte,
                              is a seasoned Marketing and Corporate
                              Communications professional with 15 years of work
                              experience in Brand Management, Advertising, Event
                              Management, Internal Communications, Digital
                              Marketing, Social Media and Public Relations.
                            </p>
                          </PartnerText>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                </Row>
              </Container>
            </section> */}
            <br/>
            <section
              style={{
                paddingTop: "30px",
                overflow: "hidden",
                background: "#fff",
              }}
            >
              <Container>
                <FoundersSection>
                  <H4> Our Founders </H4>
                </FoundersSection>
                <Row>
                  <Col lg={6} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ffffff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        <Col md={6} sm={12}>
                        <div className="text-center"><PartnerImg alt="Kamalika-Guha-Roy" src={Founder1} /></div>
                        </Col>
                        <Col md={6} sm={12}>
                          <FounderText>
                            <Title >
                              Founder & CEO
                            </Title>
                            <Partner >
                              Kamalika Guha Roy
                            </Partner>
                            <p className="bigb">
                              A relentless dreamer, she has faith in her ability
                              to accomplish all her dreams through hard work and
                              sincerity. Her dreams and aspirations make her
                              reach out to undertake seemingly impossible tasks,
                              yet she believes that her dedicated efforts shall
                              one day turn her hopes into reality. She is keen
                              to give her best shot at all she undertakes, yet
                              she doesn't hesitate to enjoy her own life to the
                              fullest.
                            </p>
                            <Ul className="social-icon-style1">
                            <List>
                              <A href="https://www.linkedin.com/in/kamalikaguharoy/">
                                <i className="fab fa-linkedin-in"></i>
                              </A>
                            </List>
                              <List>
                                <A href="https://twitter.com/kamalika">
                                  <i className="fab fa-twitter"></i>
                                </A>
                              </List>
                              <List>
                                <A href="https://www.instagram.com/kamalika25">
                                  <i className="fab fa-instagram"></i>
                                </A>
                              </List>

                            </Ul>
                          </FounderText>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className="pb-2">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#fff",
                        height: "100%",
                      }}
                    >
                      <Row>
                        <Col md={6} sm={12}>
                          <div className="text-center"><PartnerImg alt="Mithu-Sarkar" src={Founder2} /></div>
                        </Col>
                        <Col md={6} sm={12}>
                          <FounderText>
                            <Title >
                              CO-Founder and COO
                            </Title>
                            <Partner >
                              Mithu Sarkar
                            </Partner>
                            <p className="bigc">                            
                              She is the helping hand, always here for you. When
                              work piles, she just smiles. You wouldn’t know if
                              she is talking to a friend or her client, that’s
                              how good an account manager she is. Believes to
                              live in the moment.
                            </p>
                            <Ul className="social-icon-style1">
                            <List>
                              <A href="https://www.linkedin.com/in/mithusarkar/">
                                <i className="fab fa-linkedin-in"></i>
                              </A>
                            </List>
                              <List>
                                <A href="https://twitter.com/mithusarkar">
                                  <i className="fab fa-twitter"></i>
                                </A>
                              </List>
                              <List>
                                <A href="https://www.instagram.com/mithusarkar">
                                  <i className="fab fa-instagram"></i>
                                </A>
                              </List>

                            </Ul>
                          </FounderText>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <br/>
            {/* <section
              style={{
                padding: "30px 0",
                overflow: "hidden",
                background: "#fff",
              }}
            >
              <Container>
                <FoundersSection>
                  <H4> Our Founding Team </H4>
                </FoundersSection>
                <Row>
                  <Col lg={2} md={12} className="rlm pb-2 boxBorder">
                    <div className="boxBorderMob">
                      <Row>                        
                        <Col md={12} sm={12}>
                          <PartnerText2>
                            <Title2>
                              Sr. Solution Architect
                            </Title2>
                            <Partner2>
                              Pradnyesh<br className="notMob"/> Shembavanekar
                            </Partner2>
                            <div className="text-center"><PartnerImg alt="" src={dmale} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                              
                            </p>
                          </PartnerText2>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={2} md={12} className="rightm pb-2 boxBorder">
                    <div className="boxBorderMob">
                      <Row>                        
                        <Col md={12} sm={12}>
                          <PartnerText2>
                            <Title2 >
                              Full Stack Engineer
                            </Title2>
                            <Partner2>
                              Amin<br className="notMob"/> Ghoghari
                            </Partner2>
                            <div className="text-center"><PartnerImg alt="" src={dmale} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >
                             
                            </p>
                          </PartnerText2>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={2} md={12} className="rightm pb-2 boxBorder">
                    <div className="boxBorderMob">
                      <Row>                        
                        <Col md={12} sm={12}>
                          <PartnerText2>
                            <Title2 >
                              Chief Creative Office
                            </Title2>
                            <Partner2>
                              Sankar<br className="notMob"/> Ghose
                            </Partner2>
                            <div className="text-center"><PartnerImg alt="" src={dmale} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >                              
                            </p>
                          </PartnerText2>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={2} md={12} className="rightm pb-2 boxBorder">
                    <div className="boxBorderMob">
                      <Row>
                        <Col md={12} sm={12}>
                          <PartnerText2>
                            <Title2 >
                              Chief Legal Officer
                            </Title2>
                            <Partner2>
                              Nabaneeta Guha<br className="notMob"/> Roy C.
                            </Partner2>
                            <div className="text-center"><PartnerImg alt="" src={dfemale} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >                              
                            </p>
                          </PartnerText2>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={2} md={12} className="rightm pb-2 boxBorder">
                    <div className="boxBorderMob">
                      <Row>                        
                        <Col md={12} sm={12}>
                          <PartnerText2>
                            <Title2>
                              Chief Financial Officer
                            </Title2>
                            <Partner2>
                              Dipak<br className="notMob"/> Sangani
                            </Partner2>
                            <div className="text-center"><PartnerImg alt="" src={dmale} style = {{maxHeight:'15em'}}/></div>
                            <p
                              style={{
                                paddingTop: "15px",
                                lineHeight: "25px",
                                fontFamily: "MuseoSans-500",
                                fontSize: "14px",
                              }}
                            >                              
                            </p>
                          </PartnerText2>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
                            </section> */}
          </div>
        </Section>
      </div>
    );
  }
}
