import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Routes from '../Content/BaseContent'
import { BrowserRouter as Router } from 'react-router-dom'

export default class BaseLayout extends React.Component {
    render() {
        return (
            <div>
                <Router>
                <Header />
               
                    
                    <Routes />
                    
                
                <Footer />
                </Router>
                
            </div>
        )
    }
}