import React from "react";
import styled from "styled-components";
import { Col, Card } from "react-bootstrap";
import { Spring } from "react-spring/renderprops/";
// import VisibilitySensor from 'react-visibility-sensor'

const Title = styled.h6`
  font-size: 14px;
  margin-bottom: 15px;
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  /* font-weight: 600; */
  font-family: "MuseoSans-500";
  font-weight: 400;
  color: #232323;
  line-height: normal;
`;

const Icon = styled.div`
  color: #3388c9;
  position: relative;
  padding: 0px 0px;
  font-size: 60px;
  line-height: 1;
  width: 2em;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
`;
const CircleLarge = styled.div`
  left: 0;
  bottom: 0;
  width: 1.37em;
  height: 1.37em;
  background: rgba(78, 74, 200, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;

const CircleSmall = styled.div`
  top: 0;
  right: 0;
  width: 0.59em;
  height: 0.59em;
  background: rgba(128, 137, 255, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;

const Paragraph = styled.p`
  font-family: "MuseoSans-500";
  font-size: 12px !important;
  @media (max-width: 576px) {
    font-size: 11px !important;
  }
`;
const Link = styled.a`
  color: black;
  font-family: "MuseoSans-500";
  font-size: 14px !important;
  @media (max-width: 576px) {
    font-size: 12px !important;
  }
`;

const Cards = (props) => {
  return (
    <Col lg={6}>
      <Spring
        from={{
          marginTop: 100,
          opacity: 0,
        }}
        to={{
          marginTop: 0,
          opacity: 1,
        }}
        config={{
          delay: props.delay,
        }}
      >
        {(prop) => (
          <div style={prop}>
            <Card
              style={{
                alignItems: "center",
                textAlign: "center",
                border: "none",
                background: "white",
                padding: "15px",
                marginBottom: "20px",
              }}
            >
              <Icon>
                <img src={props.image} alt={props.imgTitle} title={props.title} style ={{width: props.width_i}}/>
                {/*<em className={`${props.icon}`}></em>
                <CircleLarge />
                <CircleSmall />*/}
              </Icon>
              <Title className="text-extra-dark-gray text-uppercase text-small font-weight-600 alt-font margin-5px-bottom">
                {props.loc_title}
              </Title>
              {props.content ? <Paragraph className="font-size16 line-height-28 sm-font-size14 sm-line-height-24">
                {props.content}
              </Paragraph> : ""}

              <Link className="linkClass" href={"tel:" + props.tel}>{props.telno}</Link>
              <Link className="linkClass" href={"tel:" + props.tel2}>{props.telno2}</Link>
              <Link className="linkClass" href={`mailto:${props.mailto}`}>{props.mailContent}</Link>
            </Card>
          </div>
        )}
      </Spring>
    </Col>
  );
};
export default Cards;
