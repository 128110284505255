import React from "react";

import { Helmet } from "react-helmet";

import {  
  CustomContainer  
} from "./StyledAboutUs.js";

export default class PrivacyPolicy extends React.Component {
  render() {
    
    return (
      <div>
        <Helmet>
          <title>
            Ebullientech - Our Privacy & Cookie Policy
          </title>
          <meta
            name="description"
            content="Privacy Policy Ebullientech Interactive"
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {/*<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>*/}
        </Helmet>
        <CustomContainer>
          <div className="container">
            <section
              style={{
                overflow: "hidden",
                background: "#fff",
                color: "#4e4e4e",
                textAlign: "justify",
                fontSize: "14px",
                fontFamily: 'MuseoSans-500'
              }}
            >
              <center><h1><strong>Privacy Policy</strong></h1></center>

              <br/><p ><em>The privacy policy is effective from April 1 2021 <br/></em><em>Last updated </em><em>April 15, 2021</em></p>
              <p >At Ebullientech Interactive we value the trust you place in us when you visit one of our websites or use one of our web services. We’ve built an enduring reputation for using technology and data to bring transparency, integrity and efficiency to everyone we serve. Part of our commitment to transparency and integrity is making sure you understand what data we collect, how we use it and how it may be shared.</p>
              <p >This Ebullientech Privacy Policy (“Policy”) describes how we collect and use your personal information when you visit our websites, use our mobile and web applications, or call us on the phone. In this Policy, “we” and “our” references Ebullientech and “you” and “your” references any person who uses any of our websites, services and/or applications.</p>
              <p ><h4>What is personal information?</h4></p>
              <p >By personal information, we mean information that can be used to identify, locate, or contact you, whether used alone or when combined with other identifying information. Personal information does not include information lawfully made available from government records or de-identified or aggregated consumer information.</p>
              <p >This Ebullientech Privacy Policy includes service-specific charts listing the types of personal information Ebullientech collects as well as Ebullientech’s business purpose for the collection of the specified personal information.</p>
              <p ><h4>When this Policy does not apply – Third party websites:</h4></p>
              <p >Our Websites may contain links to other websites. The information practices and the content of such other websites are governed by the privacy statements of such other websites. We encourage you to review the privacy statements of any such other websites to understand their information practices.</p>
              <h4>LOGS:</h4>
              <p >Like most websites and services delivered over the Internet, we also gather certain information and store it in log files when you interact with our Websites and Services. This information includes internet protocol (IP) addresses as well as browser type, internet service provider, URLs of referring/exit pages, operating system, date/time stamp, information you search for, locale and language preferences, identification numbers associated with your devices, your mobile carrier, and system configuration information. Occasionally, we connect personal information to information gathered in our log files as necessary to improve our Websites and Services. In such a case, we would treat the combined information in accordance with this Policy.</p>
              <p ><h4>DO NOT TRACK:</h4></p>
              <p >Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals and therefore, Ebullientech Interactive and the Site do not recognize DNT.</p>
              <p ><h4>CHILDREN’S PRIVACY:</h4></p>
              <p >The Site does not knowingly collect personally identifiable information online from children under the age of 13 without prior verifiable parental consent. If Ebullientech Interactive learns that a child under the age of 13 has submitted personally identifiable information online without parental consent, it will take all reasonable measures to delete such information from its databases and to not use such information for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law). If you become aware of any personally identifiable information we have collected from children under 13, please call us at 1800 267 1992 or email us at admn@ebullientech.com.</p>
              <p >Minors under 18 years of age may have the personal information that they provide to us deleted by sending an email to admin@ebullientech.com requesting deletion. Please note that, while we make reasonable efforts to comply with such requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all systems.</p>
              <p ><h4>YOUR RIGHTS:</h4></p>
              <p >You may have certain rights with respect to your personal information, including:</p>
              <ul >
                <li>The right to access, including the right to know the categories and specific pieces of personal information we collect; </li>
                <li>The right to know the categories of sources from which personal information is collected, the business or commercial purposes for collecting or selling personal information.</li>
                <li>The right to deletion of your personal information, subject to certain limitations under applicable law;</li>
                <li>The right to request disclosure of information collected;</li>
                <li>The right to disclosure of information disclosed for valuable consideration.</li>
              </ul>
              <p >To exercise these rights, please submit a request by emailing admin@ebullientech.com. Please be as specific as possible in relation to the personal information you wish to access. Once we receive your request, we will review it, determine whether we can verify your identity, and process the request accordingly. If we need additional information to verify your identity, we will let you know. We will respond to your request within 45 days of receipt, or notify you if we require additional time.</p>
              <p >If you would prefer, you may designate an authorized agent to make a request on your behalf.</p>
              <p ><h4>SECURITY:</h4></p>
              <p ><strong>Our responsibility. </strong> Ebullientech  is committed to protecting the security of your data. We follow the National Institute of Standards and Technology (NIST) and other national cybersecurity and privacy standards to develop and audit our security processes. We use a variety of security technologies and procedures to help protect your data from unauthorized use, access or disclosure. For example, we store the data we collect on computer systems with strict limited administrative and physical access and in controlled facilities. When we transmit certain sensitive data over the internet, we always protect it using encryption.</p>
              <p ><strong>Your responsibility. </strong>If a username and/or password is used to help protect your accounts and data, it is your responsibility to keep your information confidential and your internet connection secure. Do not share this information with anyone. If you share a computer with anyone, you should always log out before leaving an Ebullientech  Site or Service to protect access to your data from subsequent users.</p>
              <p ><h4>COOKIES, BEHAVIOR DISPLAY ADS, RETARGETING ADS:</h4></p>
              <p >At ebullientech, we believe in being transparent about how we collect information from individuals using our sites and services. Ebullientech may use cookies and similar technology to improve your experience on our sites. We use this information to customize our communications, analyze usage, measure success and promote trust to users. For example, these features make it possible for us to pre-populate your username for easier login, allow user-specific messaging or to permit advertising unique to your apparent interests. Our site may also use web beacons, which is a clear electronic image used to assist in delivering cookies on our sites and allows us to count users who have visited specific pages. We may include web beacons in promotional emails or our newsletters in order to determine if messages have been opened or clicked on.</p>
              <p >By continuing to visit or use our sites or services, you agree to the use of cookies and similar technologies for these purposes.</p>

              <p ><h4>Here’s how cookies help us monitor site analytics:</h4></p>
              <p >Ebullientech utilizes a third-party website analytics service to monitor visitor trends on our website. The service, Google Analytics, sets a cookie - a small, unique text file - on your computer so we can monitor site traffic. Ebullientech uses the information gathered to make your browsing experience more enjoyable. The cookie is for tracking purposes only and is not used to gather or to distribute personally identifiable information. For more information on Google Analytics, visit <a href="http://www.google.com/analytics/" rel="noopener noreferrer" target="_blank">http://www.google.com/analytics/.</a></p>

              <p ><h4>Here’s how behavior display ads, retargeting ads, and targeting cookies work on our social media:</h4></p>

              <p ><strong>Facebook Cookies:</strong> Ebullientech may use the pixel function of Facebook Custom Audiences to identify users who have landed on certain pages of ebullientech.com for the purposes of targeted group-orientated remarketing and advertising. The Facebook pixel stores a cookie, which means that information about your visit to our site will be shared with Facebook. Also, as part of the advanced matching functionality undertaken by Facebook, other personal information (such as telephone number, email address, Facebook ID) may be shared with Facebook in a protected form. These cookies and the information collected are used to create target groups to help Facebook deliver advertising on our behalf to users who have previously visited our site when they are on Facebook or a digital platform powered by Facebook Advertising and to track advertising success. You can modify your Facebook Ad settings to restrict these advertisements. To adjust your preferences, navigate to Settings/Adverts in your Facebook account. For more information on Facebook’s cookie policy, visit <a href="https://www.facebook.com/policies/cookies/" rel="noopener noreferrer" target="_blank">https://www.facebook.com/policies/cookies/.</a>
              </p>

              <p ><strong>LinkedIn Targeting Cookies: </strong> We may use LinkedIn Insight Tags across our site to monitor traffic from the LinkedIn social media platform. The LinkedIn Insight Tag is lightweight JavaScript code that is added to our site to enable campaign reporting and provide us with insights about visitors to our site. For more information on LinkedIn’s cookie policy,
                 visit <a href="https://www.linkedin.com/legal/l/cookie-table" rel="noopener noreferrer" target="_blank">https://www.linkedin.com/legal/l/cookie-table.</a>
              </p>

              <p ><h4>Here’s how web beacons and cookies work with our third party vendors:</h4></p>
              <p ><strong>Third Party Vendors:</strong> Ebullientech may occasionally hire other companies or contractors to provide limited services on our behalf, such as the processing of mailings, technical or engineering support, site hosting, payment processing, or performing statistical analysis of our sites or services. Those service providers will be permitted to obtain only the information they need to deliver the agreed upon service. They are required by agreement to maintain the confidentiality of the information and are prohibited from using the information for any purpose other than delivering the service to Ebullientech in accordance with our policies. However, our vendors may use aggregate data for fraud detection to help improve their services.</p>

              <p ><strong>Third Party Technologies: </strong> Third parties, such as ad servers, ad networks and data exchanges (“advertisers”) may place or recognize a unique cookie on your computer or use other technologies in order to tailor advertisements to your apparent interests and deliver those advertisements to you while you are on our sites. In addition, advertisers may use technologies to collect information about your browsing behavior on our sites which they may match with information they have previously collected. Ebullientech’s Privacy Policy does not cover any use of information that advertisers may collect from you or that you choose to provide to them. For information about how to opt out of receiving certain behavioral advertisements, please visit our opt-out and cookie policies.</p>

              <p ><strong>Social Media Widgets:</strong>Our site occasionally includes social media features, such as the Facebook Like button or Share button, and widgets, such as interactive mini-programs. These features may collect your IP address, which page(s) you are visiting on our website, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our website. Your interactions with these features are governed by the privacy statement of the company providing the features.</p>

              <p ><h4>Your Cookie Choices:</h4></p>
              <p >You can choose to opt-out of our use of cookies by setting your browser to not accept cookies. It’s important to note that by manually opting out of the use of cookies, you may significantly decrease your overall experience with our Sites and Services and remove your ability to create a customized experience.</p>
              <p>You may also choose to opt out of the collection and use of information for ad targeting through industry-standard websites such as <a href="https://optout.aboutads.info/?c=2&amp;lang=EN" rel="noopener noreferrer" target="_blank">https://optout.aboutads.info/?c=2&amp;lang=EN</a> or <a href="http://www.networkadvertising.org/choices" rel="noopener noreferrer" target="_blank">www.networkadvertising.org/choices</a>. </p>


              <p ><h4>ENGLISH VERSION CONTROLS:</h4></p>
              <p >Non-English translations of this Policy are provided for convenience only. In the event of any ambiguity or conflict between translations, the English version is authoritative and controls.</p>
              <p ><h4>CHANGES TO THIS POLICY:</h4></p>
              <p >This Policy may be updated from time to time to reflect changing legal, regulatory or operational requirements. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
              <p >If there are any material changes to this Policy, you will be notified by our posting of a prominent notice on the Websites prior to the change becoming effective.</p>
              <p >If you do not accept any changes made to this Policy, please discontinue use of the Websites and the Services.</p>
              <p ><h4>CONTACT US:</h4></p>
              <p >For more information about this Privacy Policy, please see our <a href="https://www.ebullientech.io/contact-us" rel="noopener noreferrer" target="_blank">Contact Us</a> page or email <a href="mailto:admin@ebullientech.com" rel="noopener noreferrer" target="_blank">admin@ebullientech.com</a>.</p>
              <br/>
            </section>

          </div>
        </CustomContainer>
      </div>
    );
  }
}
