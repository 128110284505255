import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Col, Card } from "react-bootstrap";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const Title = styled.h6`
  margin-bottom: 15px;
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  /* font-weight: 600; */
  font-family: "MuseoSans-500";
  font-size:0.9rem;
  font-weight: 400;
  color: #232323;
  line-height: normal;
`;

const Icon = styled.div`
  color: #3388c9;
  position: relative;
  /* padding: 20px 0; */
  font-size: 60px;
  line-height: 1;
  /* width: 2em; */
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
`;
const CircleLarge = styled.div`
  left: 0;
  bottom: 0;
  width: 1.37em;
  height: 1.37em;
  background: rgba(78, 74, 200, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;

const CircleSmall = styled.div`
  top: 0;
  right: 0;
  width: 0.59em;
  height: 0.59em;
  background: rgba(128, 137, 255, 0.1);
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;

const Paragraph = styled.p`
  margin-bottom: 0;
  line-height: 25px;
  font-family: "MuseoSans-500";
  font-size: 14px !important;
  @media (max-width: 576px) {
    font-size: 12px !important;
  }
`;

const Cards = (props) => {
  const [visible, set_visible] = useState(false);
  const ref = useRef();

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        if (isVisible) {
          set_visible(true);
        }
        return (
          <Col md={4}>
            <Spring
              from={{
                marginTop: 100,
                opacity: 0,
              }}
              to={{
                marginTop: visible ? 0 : 100,
                opacity: visible ? 1 : 0,
              }}
              // leave={{ opacity: 1, transition: "opacity .25s ease" }}

              // immediate = {false}
              config={{
                delay: props.delay,
              }}
            >
              {(prop) => (
                <div style={prop} ref={ref}>
                  <Card
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      border: "none",
                      background: "#f7f7f7",
                      padding: "10px",
                      marginBottom: "10px"
                    }}
                  >
                    <Icon>
                      <img src={props.image} alt={props.imgTitle} title={props.imgTitle} width={props.imgWidth} />
                      {/*<em className={`${props.icon}`}></em>
                      <CircleLarge />
                      <CircleSmall />*/}
                    </Icon>

                    <Title>{props.title}</Title>
                    {/*<Paragraph class="font-size16 line-height-28 sm-font-size14 sm-line-height-24">
                      {props.content}
                    </Paragraph>*/}
                  </Card>
                </div>
              )}
            </Spring>
          </Col>
        );
      }}
    </VisibilitySensor>
  );
};
export default Cards;
