import React from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Cards from "./ServiceComponents/Cards";
import { Helmet } from "react-helmet";
import Services from "../../Assets/services/services_web.png";
import OurMantra from "../../Assets/Home/OurMantra.png";

import Card_1 from "../../Assets/services/Mobile_Application-01.png";
import Card_2 from "../../Assets/services/Product_Engineering-01.png";
import Card_3 from "../../Assets/services/Video_OTT_Streaming_Technology-01.png";
import Card_4 from "../../Assets/services/eCommerce_CMS_Driven_Responsive_Websites-01.png";
import Card_5 from "../../Assets/services/Cloud_Management-01.png";
import Card_6 from "../../Assets/services/Search_Engine_Optimisation-01.png";
import Card_7 from "../../Assets/services/Social_Media_Marketing.png";
import Card_8 from "../../Assets/services/Media_Buying_PPC.png";
import Card_9 from "../../Assets/services/Business_Coaching_Start-up_Mentoring.png";
import Card_10 from "../../Assets/services/Data_Business_Analytics.png";
import Card_11 from "../../Assets/services/Design_and_UX-01.png";
import Card_12 from "../../Assets/services/Legal_Process_Outsourcing.png";
// import VisibilitySensor from "react-visibility-sensor";


const Section = styled.section`
  padding: 40px 0;
  overflow: hidden;
  background: #fff;
`;

const DivHide = styled.div`
 @media (max-width: 768px) {
      display: none;
   }
`;

const Paragraph = styled.p`
  margin-bottom: 0;
  line-height: 25px;
  font-family: "MuseoSans-500";
  font-size: 14px !important;
  @media (max-width: 576px) {
    font-size: 12px !important;
  }
`;

var linkdCS = "contact-us"
var linkdKP = "key-people"
var linkdPT = "processes-and-tools"

export default class Service extends React.Component {
  render() {

    return (
      <div>
        <Helmet>
          <title>
            Ebullientech - Our Services, What We Do
          </title>
          <meta
            name="description"
            content="Start-up India recognised leading software and app development company in Mumbai, Kolkata, India. We also offer e-commerce store development, end-to-end product engineering, 360 degree digital marketing, video CMS / OTT development, cloud computing and consulting, data and business analytics, legal process outsourcing, business coaching and start-up mentoring."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {/*<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>*/}
        </Helmet>

        <img src={Services} alt="Ebullientech Services" title="Ebullientech Services" style ={{width: '100%'}}/>

        <Section style={{ backgroundColor: "#f7f7f7" }}>
          <Container>

            <div style={{ backgroundColor: "white", borderRadius: "10px", filter: "drop-shadow(2px 4px 6px #54a3de)", padding: "20px 0"}} >
              <Row style={{ margin: "20px" }}>
                <div className="col-lg-8 col-12" style={{textAlign:"justify !important",fontFamily: "MuseoSans-500"}}>
                <strong>LEARN. BUILD. GROW</strong><br/>
                <Paragraph className="font-size16 line-height-28 sm-font-size14 sm-line-height-24">
                Start catching up with the digital disruption this pandemic has triggered. Collaborate with us to bring your local business online, start a new business, upgrade your current business, create new innovative opportunities for your clients, train your team on trending <a href={linkdPT}>tools</a>, resolve your legal problems be it personal or professional. <br/><br/>
                <a href={linkdCS}>Get in touch</a> with us today for a pro-bono consultation on your technology or legal issues from our <a href={linkdKP}>elite team</a>.</Paragraph>
                </div>
                <DivHide className="col-lg-4 text-center" style={{filter: "drop-shadow(0px 5px 5px rgb(51, 136, 201))"}}>
                  <img src={OurMantra} alt="Ebullientech Services" title="Ebullientech Services" style ={{width: '50%'}}/>
                </DivHide>
              </Row>
            </div>

            <Row style={{ marginBottom: "20px",marginTop:"40px" }}>
              <Cards
                delay=""
                image={Card_1}
                icon="fas fa-mobile"
                title="Mobile Applications"
                imgTitle="Ebullientech - Mobile Application"
                content="With smartphones gaining more and more popularity with every passing day, it’s crucial to ensure a quality presence on mobile devices. Being an experienced player in the field, we use the latest mobile development disciplines to build your responsive web presence and mobile apps. We understand that mobile presence today is one of the most effective tools for reaching all demographics, and hence we have evolved ourselves into a one-stop destination for all your mobile development needs. Be it developing a mobile responsive website - a standalone website to target mobile intensive audiences or an app that gives users a rich and fast user experience. We can do it all."
              />

              <Cards
                delay="200"
                image={Card_2}
                icon="fas fa-bullseye"
                imgTitle="Ebullientech - Product Engineering"
                title="Product Engineering"
                content="In today's pandemic laden world, transforming your business into a digitally driven operation has become essential for survival. An efficiently developed software helps in cost optimisation, employee efficacy improvement, and process implementations, which eventually boosts revenue growth. It also increases company valuation and offers many competitive advantages. At Ebullientech, we offer creative, agile and innovative product engineering solutions to remodel your business from analogue to digital. We help you draw relevance to the current pandemic stricken societal situation. Our engineers have been at the forefront of technology-driven product development and possess quality experience in the field. Their prowess and commitment enable us to deliver innovative product solutions that can amplify our customer's growth to five folds."
              />
              <Cards
                delay="300"
                image={Card_3}
                icon="fas fa-laptop"
                imgTitle="Ebullientech - Video OTT Streaming Technology (VoD and Live Streaming)"
                title="Video OTT Streaming Technology (VoD and Live Streaming)"
                content="If you are into the online video business and want to get out of platforms like YouTube, Vimeo and develop a proprietary solution, we have the right expertise and experience to solve your problem. We can help you create a Website/App, Video CMS that can transcode/encode videos, compress them, manage playback and in-video advertisements, users and subscriptions, video analytics, content distribution over the internet for Video on Demand (VoD), Live Streams and Video Conferencing solutions. If you are on a budget and want to develop an indie video content platform, we can get you launched on an all AWS inexpensive pay-as-you-go infrastructure. If you aspire to become the next unicorn in the OTT industry, we can build the next Netflix for you."
              />
            </Row>

            <Row style={{ marginBottom: "40px",marginTop:"50px" }}>
              <Cards
                delay=""
                image={Card_4}
                icon="fas fa-shopping-cart"
                imgTitle="Ebullientech - eCommerce & CMS Driven Responsive Websites"
                title="eCommerce & CMS Driven Responsive Websites"
                content="Creating an eCommerce website allows a business to break through the limitations a physical location presents. A company can sell products and collect payment on the web itself. Our customised eCommerce web solutions come in all shapes and sizes. We can build into your current website or start from scratch. Being experienced in using various platforms for developing eCommerce websites, we will use a platform that is best suited as per your requirements. We ensure that the outcome is secure, reliable, scalable and search engine friendly. Depending on your budget and needs, we help you choose a suitable CMS among WordPress, Shopify, Drupal or build a custom solution with state of the art technology stacks."
              />
              <Cards
                delay="200"
                image={Card_5}
                icon="fas fa-cloud"
                title="Cloud Computing & Consulting"
                imgTitle="Ebullientech - Cloud Computing & Consulting"
                content="Today, comprehensive cloud management is the need of the hour. A business is susceptible to security threats, data loss, and downtimes. As a cloud computing service provider, we possess comprehensive knowledge to launch and manage your business on the cloud. We offer solutions that help organisations build gen-next enterprise application platforms essential to operate in today's fast-changing digital world. Because of having served various client needs over the years, we have exposure to different public Cloud providers like Amazon Web Services (AWS), Google Cloud Platform (GCP), Microsoft Azure, and Alibaba Cloud. We use our experience and learnings from every project across all our products/applications. Depending on the client's specific needs and budget, we design and architect either an all-cloud or hybrid workload comprising multiple public clouds or combining local infrastructure and public cloud."
              />
              <Cards
                delay="300"
                image={Card_6}
                icon="far fa-life-ring"
                title="Search Engine Optimisation"
                imgTitle="Ebullientech - Search Engine Optimisation"
                content="We provide result-driven SEO services to improve your online presence. We practise only White-hat techniques, thus ensuring long-term keyword listings unaffected by Google's algorithm upgrades. We diligently work round the clock to enhance your website and app rankings and traffic numbers by applying guideline compliant engineering and SEO strategies. We employ specifically tailored blueprints specific to industry and business needs for each web and mobile application to secure top positions in Google, iOS and Android app stores. We offer personalised pricing depending on your requirements and goals. We believe in long term relationships and walk the extra mile to sustain the same."
              />
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Cards
                delay=""
                image={Card_7}
                icon="fas fa-mobile"
                imgTitle="Ebullientech - Social Media Marketing"
                title="Social Media Marketing"
                content="With the Covid-19 pandemic still at large, growing businesses offline is becoming even more challenging, and only SEO is not adequate anymore. Having a solid social media presence is a necessity. Every business, small or big, must be searchable in Google, Bing, Facebook, LinkedIn, Instagram, Twitter, and all these profiles should also be visible in their brand listings. Businesses should have optimised presences on all leading social platforms and a few specific ones, depending on their particular needs. We engage in every step of the customer acquisition process. We create product awareness, engage the target audience to connect with the products and offerings and generate quality leads, and eventually, we help convert those leads to customers. Our social media marketing techniques help take the brands a step forward through rigorous and thought-provoking campaigns."
              />
              <Cards
                delay="200"
                image={Card_8}
                icon="fas fa-bullseye"
                title="Media Buying & PPC"
                imgTitle="Ebullientech - Media Buying & PPC"
                content="Media buying and PPC guarantees the fastest way to showcase your products and services in front of your target audience on various platforms like Google, Facebook, LinkedIn, Instagram, Twitter. Based on the business's specific requirements and budget, we design the ads for a particular platform. The main crucial component of media planning is understanding the business's target audience. We take a methodical approach first to understand and define the target group (TG) then formulate the strategy around it. We keep refining the campaign tactic by analysing the results in detail. In this era of the Covid-19 pandemic, every business needs to have a solid online marketing strategy, and paid campaigns are inevitable in the game of aggressive customer acquisition."
              />
              <Cards
                delay="300"
                image={Card_9}
                icon="fas fa-laptop"
                imgTitle="Ebullientech - Business Coaching & Start-up Mentoring"
                title="Business Coaching & Start-up Mentoring"
                content="Having a solid idea isn't enough to make your mark in the global startup ecosystem. It would be best if you also had people, process and technology in place. How much budget do you need, how much should be spent on which vertical, how much on people and processes and how much on technology. Most startups fail because of overspending on sales and technology and go wrong in choosing the right people to run the business. One of our greatest strengths lies in identifying the potential failure points in any startup and help the companies formulate their business strategies with exception handling and risk management being part of the plan already."
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Cards
                delay=""
                image={Card_10}
                icon="fas fa-mobile"
                imgTitle="Ebullientech - Data & Business Analytics"
                title="Data & Business Analytics"
                content="Mathematics is the foundation of algorithms and programming, and statistics is the primary building block of Data & Business Analytics, Machine Learning (ML), Natural Language Processing (NLP) and Artificial Intelligence (AI). If you are into products driven by data and technology, then without business intelligence in place, your growth may not be as rapid as you expect it to be. Business analytics uses data to help a company take realistic market-ready decisions. It creates the path of evolution for a company. At Ebullientech, we help you transform your product/business data from OLTP to OLAP with our custom ETL tool and build your mini data warehousing solution. We generate graphs and charts that tell the story of your growth to your sponsors and investors."
              />
              <Cards
                delay="200"
                image={Card_11}
                icon="fas fa-bullseye"
                title="Design & UX"
                imgTitle="Ebullientech - Design & UX"
                content="A great product idea needs a robust technology backbone, and at the same time, it has to be visually appealing. A good design expresses your thinking visually. It helps you connect better with your target audience. For any product, the UX journey starts at logo design, then comes the iconography and imagery, gradually comes the social interaction at pre-launch and post-launch stages. Our design thinking begins with product ideation. We sit and brainstorm with the client and help them create their storyboard and visual experience design. We guide them to understand the latest design trends, colours and best practices. We add vibrance and exuberance to their product and social channel interactions."
              />
              <Cards
                delay="300"
                image={Card_12}
                icon="fas fa-laptop"
                imgTitle="Ebullientech - Legal Process Outsourcing"
                title="Legal Process Outsourcing"
                content="In the post pandemic era, availing legal aids has become highly challenging. While it is almost impossible to execute every legal matter using online/virtual mode but things like Contract Lifecycle Management (CLM), Legal Publishing, Contract Drafting and Review, Business/Market Research, Document review and e-discovery, Form completion and information population, Secretarial work and presentations can be managed remotely. Our seasoned team can be your extended arm for all things legal. Apart from drafting and legal paperwork functions, we can help you with matrimony and family court issues. We have done a significant amount of work in the field of women's legal rights and helped many to find their feet in a country where women are at their vulnerable worst."
              />
            </Row>
          </Container>
        </Section>
      </div>
    );
  }
}
