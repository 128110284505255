import styled from "styled-components";

export const CustomContainer = styled.div`
  padding: 0 20px 0px 20px;
`;

export const BodyImg = styled.img`
  height: auto;
  width: 50%;
  display: block;
  margin-right: 20px;
  margin-bottom: 10px;
  float: left;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Partner = styled.h4`
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 5px 0 20px 0;
  line-height: normal;

  ::after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #3388c9;
    margin-top: 10px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
`;

export const Partner2 = styled.h3`
  color: #232323;
  font-family: "MuseoSans-500";
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0 20px 0;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 12px;
  }

  ::after {
    content: "";
    display: block;
    height: 2px;
    background: #3388c9;
    margin-top: 10px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
`;

export const H4 = styled.h4`
  font-size: 30px;
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #232323;
  line-height: normal;
`;

export const FoundersSection = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;
export const PartnerImg = styled.img`
  max-width: 100%;
  height: auto;
`;
export const PartnerText = styled.div`
  padding: 25px;
`;
export const FounderText = styled.div`
  padding: 25px 40px 25px 0;
`;
//
// 25px 40px 25px 0
//
export const Ul = styled.ul`
  margin-bottom: 0;
  list-style: none;
`;

export const List = styled.li`
  text-align: center;
  margin-right: 4px;
  display: inline-block;
`;

export const A = styled.a`
  background: #3388c9;
  border: 1px solid #3388c9;
  color: #fff;
  border-radius: 35px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
  &:hover {
    background: white;
    color: #3388c9;
  }
`;
export const Title = styled.span`
  color: #3388c9;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

export const LinkIcon = styled.i`
  padding: 15px;
  font-size: 15px;
  color: #3387c8;
`;
