import styled from 'styled-components'


export const Button = styled.button`
            background: #efefef !important;
            box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
            color: #6f6f6f;
            position: relative;
            border: none !important;
            display: block;
            width: 100%;
            text-align: left;
            white-space: normal;
            border-radius: 4px;
            padding: 15px 50px 15px 15px;
            text-decoration: none;
            font-size: 15px;
            font-family:'MuseoSans-500';
            outline:none;
            &:after{
                background: none;
                border: 1px solid #3388C9;
                border-radius: 50%;
                content: " ${props => props.state ? "-" : "+"}";
                right: 15px;
                left: inherit;
                font-size: 18px;
                height: 25px;
                line-height: 24px;
                transform: none;
                width: 25px;
                top: 15px;
                position: absolute;
                color: #3388C9;
                text-align: center;
                
            }

        `

export const Card = styled.div`
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none !important ;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    font-family:MuseoSans-300;
`

export const CardHeader = styled.div`
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    font-family:MuseoSans-300;


`
export const CardBody = styled.div`
    padding: 20px;
    line-height: 24px;
    text-align: left;
    background-color:#f7f7f7;
    font-family: 'MuseoSans-500';
    font-size: 14px !important;
    @media (max-width: 576px){
        font-size: 12px !important;
    }



`

