import React from "react";
import BaseLayout from "./Layout/BaseLayout";
// import { ContactUsPopUP } from "./CommonComponents"; 

const App = () => {
  return (
    <div style={{ position: "relative" }}>
      <BaseLayout />
      {/* <ContactUsPopUP /> */}
    </div>
  );
};

export default App;
