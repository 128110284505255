import React from "react";
import { Switch, Route } from 'react-router-dom'
import HomePage from './Home/Home'
import Forofor from './Home/Forofor'
import AboutUs from './AboutUs/AboutUs'
import KeyPeople from './AboutUs/KeyPeople'
import ProcessesTools from './AboutUs/ProcessesAndTools'
import Service from './ServiceContent/Service'
import Contact from './Contact/Contact'
import PrivacyPolicy from './Policies/PrivacyPolicy'
import TermsConditions from './Policies/TermsConditions'
import RefundPolicy from './Policies/RefundPolicy'
import peopleP from './AboutUs/peopleP'


export const Router = () => {
  return (
    <Switch>
      <Route component={HomePage} path="/" exact />
      <Route component={AboutUs} path="/about-us" />
      <Route component={KeyPeople} path="/key-people" />
      <Route component={Service} path="/our-services" />
      <Route component={Contact} path="/contact-us" />
      <Route component={peopleP} path="/people-p" />
      <Route component={PrivacyPolicy} path="/privacy-policy" />
      <Route component={TermsConditions} path="/terms-and-conditions" />
      <Route component={ProcessesTools} path="/processes-and-tools" />
      <Route component={RefundPolicy} path="/refund-policy" />
      <Route component={Forofor} />
    </Switch>
  );
};
