import React from "react";
import { Container as Contain, Row, Col } from "react-bootstrap";
// import Carousel from './HomeComponents/Carousel/Carousel'
import HeadBanner from "./HomeComponents/Banner/Banner";
import Cards from "./HomeComponents/Card/Card";
import { Spring } from "react-spring/renderprops";
import OurMantra from "../../Assets/Home/OurMantra.png";
import HorsePower from "../../Assets/Home/HorsePower.png";
import Goal2 from "../../Assets/Home/Goal2.png";
import Efficiency2 from "../../Assets/Home/Efficiency2.png";
import Security2 from "../../Assets/Home/Security2.png";
import Campaigns2 from "../../Assets/Home/state/Campaigns-01.svg";
import Technology2 from "../../Assets/Home/state/technology-01.svg";
import Startup2 from "../../Assets/Home/state/Startup-products-01.svg";
import Millions2 from "../../Assets/Home/state/Millions-Likes-01.svg";


import VisibilitySensor from "react-visibility-sensor";
import Accordian from "./HomeComponents/Accordian/Accordian";
// import FormController from "./HomeComponents/Form/FormController";
import {
  CounterBox,
  Span,
  Count,
  Hr,
  Ctx,
  Text,
  Img,
  H4,
  Button,
  Ul,
  Li,
} from "./HomeStyled";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const ContentDiv = styled.div`
  margin-bottom: 0;
  line-height: 25px;
  font-family: "MuseoSans-500";
  font-size: 14px !important;
  word-spacing: 3px;
  @media (max-width: 576px) {
    font-size: 12px !important;
  }
`;

export default class Home extends React.Component {
  render() {
    let structuredJSON = JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            "@id": "https://www.ebullientech.io/#organization",
            "legalName": "Ebullientech Interactive",
            "url": "https://www.ebullientech.io/",
            "founders":"Kamalika Guha Roy, Mithu Sarkar",
            "sameAs": [
              "https://www.facebook.com/ebullientech",
              "https://twitter.com/ebullientech",
              "https://www.linkedin.com/company/ebullientech",
              "https://www.instagram.com/ebullientech.io"
            ],
            "contactPoint": [{
              "@type": "contactPoint",
              "telephone": "+91-9820133258",
              "contactType": "customer service"
            }],

            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.ebullientech.io/#logo",
              "url": "https://www.ebullientech.io/logo.png",
              "width": 157,
              "height": 43,
              "caption": "Ebullientech"
            },
            "image": {
              "@id": "https://www.ebullientech.io/#logo"
            }
          },
          {
            "@type": "WebSite",
            "@id": "https://www.ebullientech.io/#website",
            "url": "https://www.ebullientech.io",
            "name": "Ebullientech",
            "publisher": {
              "@id": "https://www.ebullientech.io/#organization"
            }
          },
          {
            "@type": "WebPage",
            "@id": "https://www.ebullientech.io/#webpage",
            "url": "https://www.ebullientech.io/",
            "inLanguage": "en-US",
            "name": "Custom software, mobile app development company Mumbai, Kolkata, India",
            "isPartOf": {
              "@id": "https://www.ebullientech.io/#website"
            },
            "datePublished": "2018-01-04T21:24:23+00:00",
            "dateModified": "2018-01-04T08:16:36+00:00",
            "description": "We design and develop end-to-end software applications, custom mobile applications for iOS and Android platforms.Offering a range of services from website design, ecommerce store development to top notch digital marketing. Get in touch with us today."
          }
        ]
      });
    return (
      <div>
        <Helmet>
          <title data-react-helmet="true">
            IT/ITES, Application Development, SMART Digital Marketing
          </title>
          <meta
            data-react-helmet="true"
            name="description"
            content="We design and develop end-to-end software applications, custom mobile applications for iOS and Android platforms. Offering a range of services from website design, ecommerce store development to top notch digital marketing. Get in touch with us today."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          <script data-react-helmet="true" className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>
        </Helmet>

        <HeadBanner />
        <Contain style={{ paddingTop: "90px" }}>
          <Text>Why choose Ebullientech as your technology partner?</Text>
          <Row>
            <Cards
              image={Goal2}
              delay=""
              icon="fas fa-clone "
              title="Affordable & Goal Oriented Solutions"
              imgTitle="Ebullientech - Affordable & Goal Oriented Solutions"
              linkd="our-services"
              content="At Ebullientech, we succeed with your success. Our primary focus is to assist you in achieving your vision. We believe in a goal-oriented approach to problem-solving. We thoroughly understand your business ideas and objectives and then design an affordable solution for success"
            />

            <Cards
              image={Security2}
              icon="fab fa-accusoft "
              title="Latest Technology, Security and Confidentiality"
              imgTitle="Ebullientech - Latest Technology, Security and Confidentiality"
              delay="200"
              linkd="our-services"
              content="We at Ebullientech value a client's need above all, and we support it with top-notch quality and technology offerings. We take security and confidentiality, notably seriously. We maintain 100% confidentiality with strict NDA terms. We also bring significant measures to protect client's data"
            />

            <Cards
              image={Efficiency2}
              flag = {true}
              delay="300"
              icon="fas fa-chart-pie"
              title="Efficiency & Effectiveness"
              imgTitle="Ebullientech - Efficiency & Effectiveness"
              linkd="processes-and-tools"
              content="Creativity, quality, and time efficiency - we value them the most, and they propel us to produce exceptional results in our client's best interest. Through our passion, we thrive to strengthen trust and long term relationship with our clients. Honesty, integrity and strict business ethics form our backbone"
            />
          </Row>
        </Contain>
        <div style={{ backgroundColor: "#f7f7f7", padding: "90px 0" }}>
          <Contain>
            <Row>
              <Col
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
                lg={6}
              >
                <img
                  src={OurMantra}
                  alt=""
                  className="img-fluid float-right width-100"
                />
              </Col>
              <Col lg={6}>
                <div style={{}}>
                  <H4 style>Our Mantra</H4>
                  <ContentDiv>
                    <p style={{ marginBottom: "30px" }}>
                      “All it takes is all you’ve got.”
                    </p>
                    <p>
                      At Ebullientech, we firmly believe in the value of quality
                      outputs, and It’s our love towards our work which empowers
                      us to come up with creative and quality solutions.
                    </p>
                    <p>
                      Through our commitment, we endeavour to establish trust
                      and long term relationship with our clients. Honesty,
                      integrity and strict business ethics are our greatest
                      strengths.
                    </p>
                  </ContentDiv>
                  <Button
                    href="/about-us"
                    style={{ fontFamily: "MuseoSans-500" }}
                  >
                    Learn more
                  </Button>
                </div>
              </Col>
            </Row>
          </Contain>
        </div>
        <div style={{ padding: "90px 0" }}>
          <Contain>
            <Row>
              <Col
                lg={{ spam: 6, order: 1 }}
                md={{ span: 12, order: 2 }}
                sm={{ span: 12, order: 2 }}
                xs={{ span: 12, order: 2 }}
              >
                <ContentDiv>
                  <H4>We are the horsepower your business needs.</H4>
                  <p>
                    A team of creative young souls fuelled by a highly energetic
                    management pair and advisories to yield excellent results,
                    we innovate to brew beyond the ordinary and have the courage
                    to execute.
                  </p>
                  <Ul>
                    <Li>always hungry for more</Li>
                    <Li>we put our customers first</Li>
                    <Li>communication is our key</Li>
                    <Li>
                      innovation and creativity is our driving force - we build
                      for scale
                    </Li>
                  </Ul>
                  <Button
                    href="/our-services"
                    className="butn"
                    style={{ fontFamily: "MuseoSans-500" }}
                  >
                    Learn more
                  </Button>
                </ContentDiv>
              </Col>
              <Col
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
                lg={{ span: 6, order: 2 }}
                md={{ order: 1 }}
                sm={{ order: 1 }}
                xs={{ order: 1 }}
              >
                <Img src={HorsePower} alt="" />
              </Col>
            </Row>
          </Contain>
        </div>
        {/* <Counter/> */}

        <div
          style={{
            background: "linear-gradient(-45deg, #3388C9, #459de0)",
            padding: "90px 0",
          }}
        >
          <Contain>
            <Row>
              <Col md={3}>
                <CounterBox>
                  <Span style={{
                    padding:"10px",
                  }}>
                    {/* <Icon className="far fa-user" /> */}
                    <img
                      src={Campaigns2}
                      alt=""
                      className="img-fluid "
                      style={{
                        width: "40%",
                      }}
                    />
                  </Span>
                  <Count>
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <Spring
                          delay={300}
                          from={{ number: 0 }}
                          to={{
                            number: isVisible ? 10000000 : 0,
                            // transform: isVisible ? "translateX(0)" : "translateX(200px)"
                          }}
                        >
                          {(props) => (
                            <div style={{ fontFamily: "MuseoSans-500" }}>
                              {(props.number).toLocaleString().replace(/,/g,",",)}+
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  </Count>
                  <Ctx>Ad Campaigns worth INR</Ctx>
                  <Hr />
                </CounterBox>
              </Col>
              <Col md={3}>
                <CounterBox>
                  <Span style={{
                    padding:"10px",
                  }}>
                    {/* <Icon className="far fa-smile" />*/}
                    <img
                      src={Startup2}
                      alt=""
                      className="img-fluid "
                      style={{
                        width: "40%",
                      }}
                    />
                  </Span>
                  <Count>
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <Spring
                          delay={300}
                          from={{ number: 0 }}
                          to={{
                            number: isVisible ? 20 : 0,
                            // transform: isVisible ? "translateX(0)" : "translateX(200px)"
                          }}
                        >
                          {(props) => (
                            <div style={{ fontFamily: "MuseoSans-500" }}>
                              {Math.floor(props.number)}+
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  </Count>
                  <Ctx>Tech Startup Products</Ctx>
                  <Hr />
                </CounterBox>
              </Col>
              <Col md={3}>
                <CounterBox>
                  <Span style={{
                    padding:"10px",
                  }}>
                    {/*<Icon className="far fa-flag" />*/}
                    <img
                      src={Technology2}
                      alt=""
                      className="img-fluid "
                      style={{
                        width: "40%",
                      }}
                    />
                  </Span>
                  <Count>
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <Spring
                          // delay={300}
                          from={{ number: 0 }}
                          to={{
                            number: isVisible ? 20 : 0,
                          }}
                        >
                          {(props) => (
                            <div style={{ fontFamily: "MuseoSans-500" }}>
                              {Math.floor(props.number)}+
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  </Count>
                  <Ctx>Technology Stacks</Ctx>
                  <Hr />
                </CounterBox>
              </Col>
              <Col md={3}>
                <CounterBox>
                  <Span style={{
                    padding:"10px",
                  }}>
                    {/* <Icon className="fas fa-flag" /> */}
                    <img
                      src={Millions2}
                      alt=""
                      className="img-fluid "
                      style={{
                        width: "40%",
                      }}
                    />
                  </Span>
                  <Count>
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <Spring
                          delay={300}
                          from={{ number: 0 }}
                          to={{
                            number: isVisible ? 50 : 0,
                          }}
                        >
                          {(props) => (
                            <div style={{ fontFamily: "MuseoSans-500" }}>
                              {Math.floor(props.number)}+
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  </Count>
                  <Ctx>Millions of Likes on Facebook, Instagram and Twitter</Ctx>
                  <Hr />
                </CounterBox>
              </Col>
            </Row>
          </Contain>
        </div>
        <div style={{ backgroundColor: "#f7f7f7" }}>
          <Accordian />
          {/*<Carousel />
          <FormController />*/}
        </div>
      </div>
    );
  }
}
