import React from 'react'
import './AccordianStyled.js'
import {Card,CardHeader,CardBody,Button} from './AccordianStyled'





const AccordianCard = (props) => {

    return (
        <div >
            <Card >
                <CardHeader style={{ padding: "0", border: "none" }}>
                    <h5 className="mb-0">
                        <Button state = {props.state} style={{ outline: "none" }} className="btn btn-link collapsed" aria-expanded="true" onClick={props.onClick}>{props.title}</Button>
                    </h5>
                    {props.state ?
                        <div style={{ background: "white" }}>
                            <CardBody >
                                {props.children}
                            </CardBody>
                        </div>
                        : null
                    }
                </CardHeader>
            </Card>
        </div>


    )
}
export default AccordianCard;
