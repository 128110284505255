import styled from 'styled-components'


export const CounterBox = styled.div`
    display:grid;
    justify-items:center;
    color: white  ;
    position: relative;
    text-align: center;
`

export const Span = styled.span`
    color: white;
    font-size : 36px;
    line-height: normal;

`
export const Icon = styled.i`

    display: block;
    margin-bottom:  10px;

`
export const Count = styled.h4`
    color: white;
    font-size: 28px;
    line-height: normal;
    margin: 0;
    font-weight: 600;
    font-family:'Poppins';
`

export const Hr = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    opacity: .5;
    width: 18%;
    height: 1px;
    background-color: white;
`
export const Ctx = styled.p`
    margin: 0 !important;
    font-weight: 400;
    font-size: 18px;
    font-family:'MuseoSans-500';
    line-height: 26px;
    word-spacing: 1px;

`

export const Textone = styled.h1`
    color: white;
    text-shadow: -1px 1px 0 #4a85f3, 1px 1px 0 #ff260a, 1px -1px 0 #af42ac, -1px -1px 0 #f1ea09;
    font-size:700%;
    /* font-weight:700; */
    margin-bottom:50px;
    text-align: center;
    font-family: 'MuseoSans-500';
`

export const Text = styled.h2`
    font-size:200%;
    /* font-weight:700; */
    margin-bottom:50px;
    text-align: center;
    font-family: 'MuseoSans-500';
`


export const Img = styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;

`

export const H4 = styled.h3`

    margin-bottom:15px;
    margin: 0 0 20px;
    padding: 0;
    letter-spacing: 0;
    /* font-weight: 600; */
    font-family: 'MuseoSans-500';
    font-weight: 400;
    color: #232323;
    line-height: normal;


`

export const Button = styled.a`
    text-decoration:none;
    background: linear-gradient(-45deg, #3388C9, #459de0);
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 28px;
    border-radius: 30px;
    display: inline-block;
    vertical-align: middle;

    position: relative;

    border: none;
    &:hover{
        color: #fff;
        text-decoration:none;
        background: linear-gradient(-45deg, #3388C9, #459de0);

    }

`

export const Ul =styled.ul`
   list-style-position:outside;
   margin-bottom:30px;
   list-style:none;




`
export const Li  = styled.li`
    list-style:none;
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    font-family:'MuseoSans-300';
    transition: all 0.3s ease 0s;
    line-height: 25px;
    font-family: "MuseoSans-500";
    font-size: 14px !important;
    @media (max-width: 576px) {
        font-size: 12px !important;
    }
    &:before{
        content: '';
        position: absolute;
        left: 4px;
        top: 10px;
        width: 8px;
        height: 8px;
        background: #3388C9;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        z-index: 2;
    }
    &:after{
        content: '';
        position: absolute;
        left: 0px;
        top: 6px;
        width: 16px;
        height: 16px;
        background: rgba(78, 84, 200, 0.35);
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        z-index: 1;
    }



`
