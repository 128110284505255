import styled from "styled-components";
// import {device} from '../../../../DeviceBreakpoints'

export const Text = styled.h1`
    font-size: 35px;
    text-align: start;
    color: #fff;
    margin: 0 0 20px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
    font-family: 'MuseoSans-300';
    line-height: "55px";
`


export const P = styled.p`
    width: 90%;
    margin-bottom: 30px;
    line-height: 28px;
    color: white;
    font-family:MuseoSans-300
`

export const BannerF = styled.div`
     @media (max-width: 100px) {
         background:none !important;
         display:none !important;
         content:none !important;;
    }

`

export const Button = styled.a`
    text-decoration:none;
    background: linear-gradient(-45deg, whitesmoke, white);
    cursor: pointer;
    font-weight: 400;
    color: #54a3de;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 28px;
    border-radius: 30px;
    display: inline-block;
    vertical-align: middle;

    position: relative;

    border: none;
    &:hover{
        color: #fff;
        text-decoration:none;
        background: linear-gradient(-45deg, #3388C9, #459de0);

    }

`
