import React from "react";
import { Helmet } from "react-helmet";
import WhoWeAre from "../../Assets/bb.jpeg";
import { Container, Row, Col } from "react-bootstrap";

import {
  CustomContainer,
  BodyImg,
  H4
} from "./StyledAboutUs.js";

export default class people_p extends React.Component {
  render() {    
    return (
      <div>
        <Helmet>
          <title>
            Ebullientech Journeys - The Elite Team
          </title>
          <meta
            name="description"
            content="We are a start-up India recognised, Indian Chamber of Commerce certified software and mobile app company. At Ebullientech, we leave no stone unturned to provide you with highest quality of services."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {/*<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>*/}
        </Helmet>        

        <Container>
            <Row>
              <div style={{float:"left"}}>
                <section className="heanew">
                  <span>KR</span>
                  <span>KR</span>
                </section>
              </div>
              <Row>
                <div className="boldBig">About</div>
              </Row>
            </Row>
            <Container >
              <Row className="pt-5">
                <Col lg={6} className="col-6">
                  <img className="imageC" src={WhoWeAre} alt="" />
                </Col>
                <Col lg={6} className="col-6">
                  <section className="text-left heanewR">
                    <span>I'm</span>
                    <span className="lineUnder">A Designer</span>
                  </section>
                  <Row className="pt-5">
                    <Col lg={1} className="col-6">
                      <div className="rowTest">ABOUT</div>
                    </Col>
                    <Col lg={11} className="col-6 peraDesign">
                      A small river named Duden flows by their place and supplies it with the necessary 
                      regelialia. It is a paradisematic country, in which roasted parts of sentences fly into 
                      your mouth. Even the all-powerful Pointing has no control about the blind texts it is an 
                      almost unorthographic life.
                      <div className="pt-2 pb-2">
                          <a href="https://www.facebook.com/ebullientech"><i className="socialIcoOne fab fa-facebook-f" /></a>
                          <a href="https://www.linkedin.com/company/ebullientech"><i className="socialIco fab fa-linkedin-in" /></a>
                          <a href="https://www.instagram.com/ebullientech.io"><i className="socialIco fab fa-instagram" /></a>
                          <a href="https://api.whatsapp.com/send?phone=919830679611"><i className="socialIco fab fa-whatsapp" /></a>                          
                      </div>
                      <button className="buttonMe p-1 px-2">Contact Me!</button>
                    </Col>
                  </Row>                  
                </Col>
              </Row>
            </Container>            
        </Container>
      </div>
    );
  }
}
