import React from "react";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import Logo from "../../Assets/logo.png";
import "./Header.css";
import styled from "styled-components";
import mentor from "../../Assets/speak.mp3";
import { Link } from "react-router-dom";


const Anchor = styled(Nav.Link)`
  margin-right: 10px;
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 21px 0.5rem;
  color: #232323 !important;
  letter-spacing: 0.5px;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  font-family: MuseoSans-500;

  &:hover {
    color: #55a3de !important;
    text-decoration: none;
  }
`;

export default class Header extends React.Component {
  state = {
    isScrolled: false,
    isClickSpeak: "fa fa-volume-up",
    intervalID: null,
  };

  handleClick = () => {
    let audio = new Audio(mentor);
    audio.play();
    setTimeout(
      function () {
        this.setState({ isClickSpeak: "fa fa-volume-down" });
      }.bind(this),
      100
    );
    setTimeout(
      function () {
        this.setState({ isClickSpeak: "fa fa-volume-up" });
      }.bind(this),
      200
    );
    setTimeout(
      function () {
        this.setState({ isClickSpeak: "fa fa-volume-down" });
      }.bind(this),
      400
    );
    setTimeout(
      function () {
        this.setState({ isClickSpeak: "fa fa-volume-up" });
      }.bind(this),
      600
    );
  };

  componentWillMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: true });
      }
    });
  }

  render() {
    return (
      <Navbar collapseOnSelect bg="white" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand style={{ padding: "0px 0px 0px 0px" }} href="/">
            <img
              alt="Ebullientech"
              src={Logo}
              width="157"
              height="43"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>

          <Navbar.Toggle
            style={{ outline: "none" }}
            aria-controls="basic-navbar-nav"
          ></Navbar.Toggle>

          <Navbar.Collapse>
            <Nav className="ml-auto">
              <Nav.Link eventKey="1" className="navLinkCss" as={Link} to="/">Home</Nav.Link>
              <NavDropdown title="About" id="collasible-nav-dropdown">
                <NavDropdown.Item eventKey="2" className="" as={Link} to="/about-us">About Us</NavDropdown.Item>
                <NavDropdown.Divider />
                {/* <NavDropdown.Item eventKey="3" className="" as={Link} to="/key-people">Key People</NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item eventKey="4" className="" as={Link} to="/processes-and-tools">Processes and Tools</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link eventKey="5" className="navLinkCss" as={Link} to="/our-services">Services</Nav.Link>
              <Nav.Link eventKey="6" className="navLinkCss" as={Link} to="/contact-us">Contact</Nav.Link>{" "}
              {/* <Anchor
                target="_blank"
                href="https://www.kamalika.io/topics/ebullientech-news-and-events"
              > News & Events</Anchor> */}
              <NavDropdown title="Blogs" id="collasible-nav-dropdown">
                <NavDropdown.Item eventKey="7" className="" target="_blank" href="https://blogs.ebullientech.io">Corporate Blogs</NavDropdown.Item>
                <NavDropdown.Divider />                
                <NavDropdown.Item eventKey="9" className="" target="_blank" href="https://www.kamalika.io">CEO's Blog</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="10" className="" target="_blank" href="https://www.mithusarkar.com">COO's Blog</NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown title="Tools" id="collasible-nav-dropdown">
                <NavDropdown.Item eventKey="11" className="" target="_blank" href="https://coronaweb.ebullientech.io">Covid-19 Tracker</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
