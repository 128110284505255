import React from 'react'
import { Router } from './Router'


const BaseContent = () => {
    return (
        <div style= {{position:"relative",top:"auto"}}>
            <Router/>
        </div>
    );

}

export default BaseContent;