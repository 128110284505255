import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'
import { Input } from 'reactstrap'
import { P, H3, Control, SectionHeading, ValidationText, Btn, AlertMessage,StyledCol } from "./FormStyled";
import ReCAPTCHA from "react-google-recaptcha";



class Form extends Component {


    render() {

        return (
            <div style={{ padding: "10px 0" }}>
                <Container >
                    <div>
                        <SectionHeading className="section-heading">
                            <H3>Get in Touch</H3>
                            <P><b>"There is only one rule for being a good talker – learn to listen" ~ Christopher Morley</b></P>
                            <P className=" ">One of our greatest strengths lies in our team's ability to listen to our customer's problems no matter what time of the day it is and where they are. We are available 24x7 on Email, WhatsApp and Phone. We have also established a robust chain of internal communication by using some of the best in class <a href="/processes-and-tools">technology tools</a>. You can also ask a question about our <a href="/our-services">services</a> through the contact form below.</P>
                        </SectionHeading>
                    </div>

                    <div>
                        <Row>
                            <Col lg={4} md={4} style={{ marginBottom: "15px" }}>
                                <Input value={this.props.name} placeholder="Your Name: " onChange={this.props.handleName} disabled={this.props.disabled ? "disabled" : ""} />
                                <ValidationText>{this.props.nameValidation}</ValidationText>
                            </Col>
                            <Col lg={4} md={4} style={{ marginBottom: "15px" }}>
                                <Input value={this.props.phone} placeholder="Telephone: " onChange={this.props.handlePhone} disabled={this.props.disabled ? "disabled" : ""} />
                                <ValidationText>{this.props.phoneValidation}</ValidationText>
                            </Col>
                            <Col lg={4} md={4} style={{ marginBottom: "15px" }}>
                                <Input value={this.props.email} placeholder="Email:" onChange={this.props.handleEmail} disabled={this.props.disabled ? "disabled" : ""} />
                                <ValidationText>{this.props.emailValidation}</ValidationText>
                            </Col>
                            <Col md={12}>

                                <textarea
                                    value={this.props.message}
                                    ref="form"
                                    disabled={this.props.disabled ? "disabled" : ""}
                                    placeholder="Message:"
                                    style={{ marginBottom: "15px" }}
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    onChange={this.props.handleMessage}
                                />
                                <ValidationText>{this.props.messageValidation}</ValidationText>

                            </Col>

                            <StyledCol lg={{ offset: 0 ,span:12}} md ={{offset:3}}  sm={{ offset: 2 }} xs={{ offset:2 }}>
                                {/* ReCAPTCHA Integration */}
                                <ReCAPTCHA
                                    sitekey="6LeDs_cUAAAAAKSlytMOlwbcWknBU_nCwJDkJslP"
                                    onChange={this.props.captchaOnChange}
                                />

                                <ValidationText>{this.props.verifiedValidation}</ValidationText>
                            </StyledCol>


                            <Col lg={{ offset: 0 }} md ={{offset:4}} sm={{ offset: 3 }} xs={{ offset: 2, }}>
                                <Control className="mfControls ">
                                    <Btn disabled={this.props.disabled || this.props.isVerified === null ? "disabled" : ""} style={{ outline: "none" }} onClick={this.props.onSubmit}>
                                        {this.props.disabled ?
                                            <span className="btn-label">
                                                <i className={this.props.responseStatus ? "" : "fas fa-spinner fa-spin"} />
                                            </span>
                                            : null}
                                        Submit Comment
                                </Btn>
                                </Control>
                            </Col>

                        </Row>


                    </div>

                    <br />

                    {this.props.response ? <AlertMessage response_status={this.props.responseStatus} >{this.props.alertText}</AlertMessage> : ""}


                </Container>

            </div>
        )

    }
}
export default Form;
