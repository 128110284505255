import React from "react";
import { Col } from "react-bootstrap";
import { Title, Icon, CircleLarge, CircleSmall, Paragraph ,SCard} from "./CardStyled";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const Cards = (props) => {
  return (
    <Col lg={4} md={4}>
      <VisibilitySensor partialVisibility offset={{ top: -40, bottom: null }}>
        {({ isVisible }) => (
          <Spring
            from={{
              opacity: 0,
            }}
            to={{
              opacity: isVisible ? 1 : 0,
            }}
            config={{
              delay: props.delay,
            }}
          >
            {(prop) => (
              <div style={prop}>
                <SCard
                  style={{

                  }}
                >
                  <Icon flag = {props.flag}>
                    <img src={props.image} alt={props.imgTitle} title={props.imgTitle} style ={{width: '100px'}}/>
                    {/*<CircleLarge/>
                    <CircleSmall />*/}
                  </Icon>

                  <Title>{props.title}</Title>
                  <Paragraph className="font-size16 line-height-28 sm-font-size14 sm-line-height-24">
                    {props.content}
                    <a href={props.linkd}>... Know More</a>
                  </Paragraph>
                </SCard>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    </Col>
  );
};
export default Cards;
